import { PopupRequest } from "@azure/msal-browser";


// Config object to be passed to Msal on creation
export const msalConfig = {

    auth: {
        tenentId: "18b84a57-1d0b-4649-8bd5-bc6f41877fd4",
        clientId: "69e26c80-c6b4-44c4-8236-18e0b8c06166",
        authority: "https://login.microsoftonline.com/18b84a57-1d0b-4649-8bd5-bc6f41877fd4/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        //redirectUri: "http://localhost:3000/authresponse",
        //redirectUri: "https://lighthousecore-dev.azurewebsites.net/authresponse",
        //redirectUri: "https://lighthousecore-qa.azurewebsites.net/authresponse",
        //redirectUri: "https://lighthousecore-prodtest.azurewebsites.net/authresponse",
        //redirectUri: "https://lighthousecore-demo.azurewebsites.net/authresponse",
        redirectUri: "https://core.lighthouseautismcenter.com/authresponse",
        instance: "https://login.microsoftonline.com/",
        domain: "lighthouseaba.com",
        clientSecret: "hU18Q~qSw7pmanxGNOrP5wj6IiaEjlrL64-63dv0",
        scope: "api://727c5db8-f98d-40e9-8ec0-516494d8e4a3/access_as_user"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};



// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["api://727c5db8-f98d-40e9-8ec0-516494d8e4a3/Core-API.Read api://727c5db8-f98d-40e9-8ec0-516494d8e4a3/Core-API.ReadWrite api://727c5db8-f98d-40e9-8ec0-516494d8e4a3/access_as_user"]
};



// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const apiConfig = {
    //apiEndpoint: "https://localhost:7083"
    //apiEndpoint: "https://lighthousecoreapi-dev.azurewebsites.net"
    //apiEndpoint: "https://lighthousecoreapi-qa.azurewebsites.net"
    //apiEndpoint: "https://lighthousecoreapi-prodtest.azurewebsites.net"
    //apiEndpoint: "https://lighthousecoreapi-demo.azurewebsites.net"
    apiEndpoint: "https://api.lighthouseautismcenter.com"

};

//export const isProduction = false;
export const isProduction = true;
