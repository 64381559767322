// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";


// Sample app imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { GlobalContext } from "../../utils/GlobalContext";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { EDPNContext } from "./EDPNContext";
import { CredentialsContext } from "../credentials/CredentialsContext";
import { GrammarContext, GrammarContextProvider } from "../../utils/GrammarContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AbsentReason, APIReturnObject, ChoiceOption, Client, ClientComment, CPTCode, EDPNAnswer, EDPNAnswerObject, EDPNQuestion, FutureTimeObject, Status, Therapist, TherapistSmallObject, Schedule } from "../../utils/Definitions";
import { Navigate, useNavigate } from "react-router-dom";
import { EDPNQuestionNode } from "../../components/edpn/EDPNQuestionNode";
import Select, { SingleValue } from "react-select";


import pic1562 from '../../images/1562.png';
import pic222 from '../../images/222.png';
import pic255 from '../../images/255.png';
import pic889 from '../../images/889.png';
import pic94 from '../../images/94.png'; 
import { addDays, format, isAfter, isBefore, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import { ClassNames } from "@emotion/react";
import { DatePicker } from "@mui/x-date-pickers";
import { time } from "console";
import { timingSafeEqual } from "crypto";
import { ModalNotification } from "../../components/ModalNotification";
import { EDPNViewLogCard } from "../../components/edpn/EDPNViewLogCard";
import ProtectedPage from "../../utils/ProtectedPage";
import ProtectedComponent from "../../utils/ProtectedComponent";
import { ProfilePicture } from "../../components/ProfilePicture";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import { ModalConfirm } from "../../components/ModalConfirm";
import { PopperGrammarBoxes } from "../../components/PopperGrammarBoxes";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

//only renders if authenticated
const EDPNEditLogContent = () => {

    const navigate = useNavigate();

    const globalContext = useContext(GlobalContext);

    const edpnContext = useContext(EDPNContext);

    const grammarContext = useContext(GrammarContext);

    const credentialsContext = useContext(CredentialsContext);

    const [grammarMistakeModalActive, setGrammarMistakeModalActive] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);

    const [editLogButtonDisabled, setEditLogButtonDisabled] = useState<boolean>(true);

    const [answerObject, setAnswerObject] = useState<EDPNAnswerObject | null>(null);

    const [questions, setQuestions] = useState<EDPNQuestion[]>();

    const [filteredQuestions, setFilteredQuestions] = useState<EDPNQuestion[]>();

    const [selectedQuestion, setSelectedQuestion] = useState<EDPNQuestion | null>(null);

    const [billingDate, setBillingDate] = useState<Date | null>(new Date());
    const [timeIn, setTimeIn] = useState<Date | null>(null);
    const [timeInText, setTimeInText] = useState<string>("");

    const [timeOut, setTimeOut] = useState<Date | null>(null);
    const [timeOutText, setTimeOutText] = useState<string>("");

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);
    const [saveNoteLoading, setAddLogLoading] = useState<boolean>(false);

    const [errorModalActive, setErrorModalActive] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [futureModalActive, setFutureModalActive] = useState<boolean>(false);
    const [futureMessage, setFutureMessage] = useState<string>("");

    const [questionStack, setQuestionStack] = useState<EDPNQuestion[]>([]);

    const [editTimeVerification, setEditTimeVerification] = useState<boolean>(false);

    const [selectedReason, setSelectedReason] = useState<AbsentReason | null>();

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);
    function ProfilePictureOnCLose() {
        setSelectedClientForModal(null);
    }

    const [antecedentConfirmationModalActive, setAntecedentConfirmationModalActive] = useState<boolean>(false);

    const [submitNoteConfirmationModalActive, setSubmitNoteConfirmationModalActive] = useState<boolean>(false);
    const [submitNoteConfirmationMessage, setSubmitNoteConfirmationMessage] = useState<string>("");

    const [saveNoteConfirmationModalActive, setSaveNoteConfirmationModalActive] = useState<boolean>(false);
    const [saveNoteConfirmationMessage, setSaveNoteConfirmationMessage] = useState<string>("");

    const [credentialsModalActive, setCredentialsModalActive] = useState<boolean>(false);

    const [overlapModalActive, setOverlapModalActive] = useState<boolean>(false);
    const [overlapMessage, setOverlapMessage] = useState<string>("");

    const [timeMessage, setTimeMessage] = useState<string>("");

    const [bcbaEditReason, setBCBAEditReason] = useState<string>("");

    var tempFlatQuestions: EDPNQuestion[] = [];
    const [flatQuestions, setFlatQuestions] = useState<EDPNQuestion[]>([]);

    const [commentsLoading, setCommentsLoading] = useState<boolean>(true);

    const [clientComment, setClientComment] = useState<string>("");
    const [clientComments, setClientComments] = useState<ClientComment[]>([]);

    const [changeToPendingChecked, setChangeToPendingChecked] = useState<boolean>(false);

    const [changeToPendingModalActive, setChangeToPendingModalActive] = useState<boolean>(false);

    const [groupLogs, setGroupLogs] = useState<EDPNAnswerObject[] | null>(null);
    const [selectedGroupLog, setSelectedGroupLog] = useState<EDPNAnswerObject | null>(null);

    const [billingDaySchedule, setBillingDaySchedule] = useState<Schedule | null>();
    const [isAutosaving, setIsAutoSaving] = useState<boolean>(false);

    const [FilteredCPTCodes, setFilteredCPTCodes] = useState<CPTCode[] | null>();

    //97156 ONLY SHOW UP FOR IOWA STATES + EAST MOLINE
    useEffect(() => {
        if (edpnContext.CPTCodes) {

            // filter out 97156
            var tempcodes: CPTCode[] = edpnContext.CPTCodes.filter(x => x.value != "97156");

            //add 97156 for IOWA and EAST MOLINE ONLY
            if (edpnContext.selectedCenter && (edpnContext.selectedCenter.state == "IA" || edpnContext.selectedCenter.centerId == 48)) {
                edpnContext.CPTCodes.filter(x => x.value == "97156").forEach(x => tempcodes.push(x));
            }

            setFilteredCPTCodes(tempcodes);
        }

    }, [edpnContext.CPTCodes]);

    useEffect(() => {
        console.log("edpnContext.CPTCodes:", edpnContext.CPTCodes);
    }, [edpnContext.CPTCodes]);

    useEffect(() => {
        console.log("filtered cptcodes:", FilteredCPTCodes);
    }, [FilteredCPTCodes]);

    useEffect(() => {

        if (edpnContext.selectedCPTCode && answerObject) {
            setAnswerObject({ ...answerObject, cptcode: edpnContext.selectedCPTCode.value });
        }
    }, [edpnContext.selectedCPTCode]);

    useEffect(() => {
            UpdateQuestions();
    }, [edpnContext.selectedEditAnswer]);

    useEffect(() => {
        console.log("edpnContext.selectedEditAnswer:", edpnContext.selectedEditAnswer);
    }, [edpnContext.selectedEditAnswer]);

    useEffect(() => {
        if (!edpnContext.selectedClient) {
            navigate("/edpn");
        }
    })

    useEffect(() => {

        if (editTimeVerification) {
            TimeInValidateTime(false, null);
            TimeOutValidateTime(false, null);
        }
    }, [editTimeVerification])


    useEffect(() => {

        if (!answerObject) {
            ResetAnswerObject();
        }
    }, [])

    useEffect(() => {
        if (billingDate) {
            UpdateComments();
            GetSchdedule();
        }
    }, [billingDate])

    useEffect(() => {
        if (edpnContext.selectedEditAnswer?.groupId) {
            UpdateLogsInGroup();
        }
    }, [edpnContext.selectedEditAnswer?.groupId])

    useEffect(() => {
        if (groupLogs && groupLogs.length > 0) {
            var currentLog = groupLogs.find(x => x.logId == edpnContext.selectedEditAnswer?.logId);

            console.log("current log:", currentLog);
            if (currentLog) {
                setSelectedGroupLog(currentLog);
            }
        }
    }, [groupLogs])

    useEffect(() => {

        if (edpnContext.selectedCPTCode && questions) {
            if (questions) {
                setFilteredQuestions(questions.filter(question => String(question.cptcode) == String(edpnContext.selectedCPTCode?.value)));
            }
        }
    }, [edpnContext.selectedCPTCode, questions]);


    useEffect(() => {
        if (questions) {
            //reset tempflatquestions, then regenerate them.
            tempFlatQuestions = [];

            GenerateFlatQuestions(questions);

            setFlatQuestions(tempFlatQuestions);
        }

    }, [questions]);

    function GenerateFlatQuestions(questions: EDPNQuestion[]) {
        tempFlatQuestions = tempFlatQuestions.concat(questions);

        questions.forEach((q) => {
            if (Array.isArray(q.children)) {
                GenerateFlatQuestions(q.children);
            }
        })
    }

    function GetSchdedule() {
        if (billingDate && edpnContext.selectedEditAnswer) {
            var dayID = new Date(billingDate).getDay() + 1;

            var schedule = credentialsContext.schedules.find(x => x.therapyScheduleId == edpnContext.selectedEditAnswer?.therapyScheduleId && x.dayOfWeekId == dayID);

            if (schedule) {
                setBillingDaySchedule(schedule);
            }
        }
    }

    function UpdateQuestions() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetQuestions?app=DPN";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("questions:", result.length);
                        setQuestions(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetQuestions" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    //GetLogsInGroup
    function UpdateLogsInGroup() {
        if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.groupId) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetLogsInGroup?groupID=" + edpnContext.selectedEditAnswer?.groupId;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setGroupLogs(result);
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/GetLogsInGroup" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }

    function UpdateComments() {
        if (edpnContext.selectedClient && edpnContext.selectedClient.clientId && billingDate) {
            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/Clients/GetCommentsByDate?clientID=" + edpnContext.selectedClient?.clientId + "&date=" + new Date(new Date(billingDate).toLocaleString() + " +0000").toISOString();

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("comments:", result.length);
                            setClientComments(result);
                            setCommentsLoading(false);
                        },
                        (error) => {
                            setCommentsLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetCommentsByDate" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }

    function SuccessModalClose() {
        NavigateBack();
    }

    function GetQuestionByID(id: number, array: EDPNQuestion[]): EDPNQuestion | undefined {
        for (const node of array) {
            if (node.questionId === id) return node;
            if (node.children) {
                const child = GetQuestionByID(id, node.children);
                if (child) return child;
            }
        }
    }

    useEffect(() => {
        if (edpnContext.selectedEditAnswer) {
            console.log("cptcode:", edpnContext.selectedEditAnswer?.cptcode);
            console.log("answers:", edpnContext.selectedEditAnswer?.answers);
        }
    }, [edpnContext.selectedEditAnswer, edpnContext.selectedEditAnswer?.answers])

    useEffect(() => {

        if (edpnContext.selectedEditAnswer && questions && FilteredCPTCodes && edpnContext.absentReasons) {
            //autoselect cptcode
            var cptcoderesult = FilteredCPTCodes.find(x => x.value == edpnContext.selectedEditAnswer?.cptcode)
            if (cptcoderesult) {
                edpnContext.setSelectedCPTCode(cptcoderesult);
            }

            //autoselect billing date
            if (edpnContext.selectedEditAnswer.billingDate) {
                setBillingDate(new Date(edpnContext.selectedEditAnswer.billingDate));
            }

            //autoselect time in
            if (edpnContext.selectedEditAnswer.startTime) {
                setTimeInText(new Date(edpnContext.selectedEditAnswer.startTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
                setEditTimeVerification(true);
            }

            //autoselect time out
            if (edpnContext.selectedEditAnswer.endTime) {
                setTimeOutText(new Date(edpnContext.selectedEditAnswer.endTime).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit"}));
                setEditTimeVerification(true);
            }

            //autoselect tardy reason
            var tardyresult = edpnContext.absentReasons.find(x => x.id == edpnContext.selectedEditAnswer?.tardyReasonId)
            if (tardyresult) {
                setSelectedReason(tardyresult);
            }

            //set question stack from answer
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.answers && edpnContext.selectedEditAnswer.answers[0].questionId && questions) {

                var Continue = true;
                var QuestionID = edpnContext.selectedEditAnswer.answers[0].questionId;
                while (Continue) {

                    var questionresult = GetQuestionByID(QuestionID, questions);
                    if (questionresult) {

                        //push only tree into stack, ignore actual question forms
                        if (questionresult.type == "none") {
                            var tempQuestionStack = questionStack;
                            tempQuestionStack.push(questionresult);

                            setQuestionStack(tempQuestionStack);
                        }

                        //if question has a parent, set parent as next question id
                        if (questionresult.parentQuestionId) {
                            QuestionID = questionresult.parentQuestionId;
                        }
                        else {
                            Continue = false;
                        }
                    }
                    else {
                        Continue = false;
                    }
                }
            }
            

        }
    }, [edpnContext.selectedEditAnswer, questions, FilteredCPTCodes]);

    function ResetAnswerObject() {

        setAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: edpnContext.selectedClient ? edpnContext.selectedClient.clientId : null,
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: null,
            dpnstatusId: null,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            absent: false,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
            lateReasonId: null,
        })

    }

    useEffect(() => {
        if (answerObject) {
            UpdateDiableAddLogButton();
        }
    }, [answerObject])

    
    useEffect(() => {
        UpdateDiableAddLogButton();
    }, [timeIn, timeOut, billingDate, bcbaEditReason])

    useEffect(() => {
        //EditNote(false);
    }, [timeIn, timeOut])

    useEffect(() => {

        if (!edpnContext.selectedServicePlace && edpnContext.servicePlaces && edpnContext.selectedEditAnswer) {
            //autoselect edit

            var placeofservicefound = edpnContext.servicePlaces.find(x => x.value == String(edpnContext.selectedEditAnswer?.placeOfServiceId));

            if (placeofservicefound) {
                edpnContext.setSelectedServicePlace(placeofservicefound);
            }
        }
        else if (!edpnContext.selectedServicePlace && edpnContext.servicePlaces) {
            //autoselect fresh
            edpnContext.setSelectedServicePlace(edpnContext.servicePlaces[0]);
        }
    }, [edpnContext.servicePlaces])

    function NavigateBack() {
        navigate(-1);
    }

    async function NoteValidation(token: string, edpnanswerobject: EDPNAnswerObject | null, isComplete: boolean) {
        if (!isComplete) {
            //autosave ignore validation
            return false;
        }
        else {
            if (edpnanswerobject) {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(edpnanswerobject)
                };

                const response = await fetch(apiConfig.apiEndpoint + "/EDPN/NoteValidation", options);
                var result: APIReturnObject = await response.json();
                if (result.status === Status.SUCCESS) {
                    setSuccessModalActive(true);
                    return false;
                }
                else if (result.status === Status.FAIL) {
                    setOverlapMessage(result.message ? result.message : "Unknown error.");
                    setOverlapModalActive(true);
                    return true;
                }
                else if (result.status === Status.ERROR) {
                    globalContext.setErrorModalProps({ errorMessage: result.message, errorLocation: "/EDPN/NoteValidation" });
                    globalContext.setErrorModalActive(true);
                    return true;
                }
            }
            else {
                return true;
            }
        }
    }

    function SaveNoteCheck() {
        //show the confirmation modal and edit note if confirm is pressed
        if (edpnContext.selectedClient) {
            var text = "Edit note for <strong>" + edpnContext.selectedClient.fullName + "</strong>";
            if (timeInText.length > 0) {
                text += " with a start time of <strong>" + timeInText + "</strong>";
            }
            else {
                text += " with no start time";
            }

            if (timeOutText.length > 0) {
                text += " and with an end time of <strong>" + timeOutText + "</strong>";
            }
            else {
                text += " and with no end time";
            }

            text += "?";

            setSaveNoteConfirmationMessage(text);
            setSaveNoteConfirmationModalActive(true);
        }
    }

    function PreSubmissionGrammarCheck() {
        // Do the call to get the grammar mistakes here
        setAddLogLoading(true);
        grammarContext.CheckAllGrammar().then((hasMistakes: boolean) => {
            if (hasMistakes) {
                setGrammarMistakeModalActive(true);
            }
            else {
                AntecedentStrategiesCheck();
            }
            setAddLogLoading(false);
        });
    }

    function AntecedentStrategiesCheck() {
        //notify when less than 2 antecedent strategies are selected

        var AntecedentQuestions: EDPNQuestion[] = flatQuestions.filter(x => x.label == "Antecedent Strategies" && x.type == "multichoice");

        var found: EDPNAnswer | undefined = answerObject?.answers?.find(answer =>
            AntecedentQuestions.some(question => question.questionId === answer.questionId)
        );

        if (found && found.textValue && found.textValue.split(",").length < 2) {
            console.log("LESS THAN 2 ANTECEDENTS");
            setAntecedentConfirmationModalActive(true);
        }
        else {
            SubmitNoteCheck();
        }
    }

    function SubmitNoteCheck() {
        //show the confirmation modal and edit note if confirm is pressed
        if (edpnContext.selectedClient) {
            var text = "Edit note for <strong>" + edpnContext.selectedClient.fullName + "</strong>";
            if (timeInText.length > 0) {
                text += " with a start time of <strong>" + timeInText + "</strong>";
            }
            else {
                text += " with no start time";
            }

            if (timeOutText.length > 0) {
                text += " and with an end time of <strong>" + timeOutText + "</strong>";
            }
            else {
                text += " and with no end time";
            }

            text += "?<div></div>";
            text += "<strong>Please remember to graph your sessions in central reach.</strong>";


            setSubmitNoteConfirmationMessage(text);
            setSubmitNoteConfirmationModalActive(true);
        }
    }

    // AUTO REFRESH EVERY 1/2 sec for autosave
    useEffect(() => {
        const intervalId = setInterval(() => {

            //if autosave flag is set, and we are not currently autosaving, autosave
            if (!isAutosaving && edpnContext.autosaveFlag) {
                console.log("Autosaving");
                edpnContext.setAutosaveFlag(false);
                EditNote(false);
            }

        }, (0.5 * 1000)); // 0.5 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });

    function EditNote(isComplete: boolean) {
        if (answerObject?.answers?.length == 0) {
            console.log("NOTE CORRUPTION DETECTED, REJECTING SAVE");
            //globalContext.setErrorModalProps({ errorMessage: "Note corruption detected! Save was prevented to avoid corruption. Please navigate out and back into the log and try again. If this error continues, please contact I.T.", errorLocation: "/EDPN/UpdateEDPN" });
            //globalContext.setErrorModalActive(true);
            return;
        }

        setIsAutoSaving(true);

        if (isComplete) {
            setAddLogLoading(true); // Show loading spinner
        }

        var finalAnswerObject = answerObject;

        if (answerObject && finalAnswerObject) {
            // Set cptcode if selectedCPTCode is available
            if (edpnContext.selectedCPTCode && edpnContext.selectedCPTCode.value) {
                finalAnswerObject.cptcode = edpnContext.selectedCPTCode.value;
            }

            // Set logId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.logId) {
                finalAnswerObject.logId = edpnContext.selectedEditAnswer.logId;
            }

            // Set dpnstatusId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.dpnstatusId) {
                finalAnswerObject.dpnstatusId = edpnContext.selectedEditAnswer.dpnstatusId;
            }

            // Set createdDate if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.createdDate) {
                finalAnswerObject.createdDate = new Date(new Date(edpnContext.selectedEditAnswer.createdDate).toLocaleString() + " +0000");
            }

            // Set times
            if (billingDate) {
                finalAnswerObject.billingDate = new Date(billingDate.toLocaleString() + " +0000")
            }

            if (timeIn) {
                finalAnswerObject.startTime = new Date(timeIn.toLocaleString() + " +0000")
            }

            if (timeOut) {
                finalAnswerObject.endTime = new Date(timeOut.toLocaleString() + " +0000")
            }

            // Set placeOfServiceId if selectedServicePlace is available
            if (edpnContext.selectedServicePlace) {
                finalAnswerObject.placeOfServiceId = Number(edpnContext.selectedServicePlace.value);
            }

            // Set centerId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.centerId) {
                finalAnswerObject.centerId = edpnContext.selectedEditAnswer.centerId;
            }

            // Set podId if selectedEditAnswer is available
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.podId) {
                finalAnswerObject.podId = edpnContext.selectedEditAnswer.podId;
            }

            // Set approvedDate
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.approvedDate) {
                finalAnswerObject.approvedDate = new Date(new Date(edpnContext.selectedEditAnswer.approvedDate).toLocaleString() + " +0000");
            }

            // Set approvedByID
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.approvedById) {
                finalAnswerObject.approvedById = edpnContext.selectedEditAnswer.approvedById;
            }

            //copy therapistid
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.therapistId) {
                finalAnswerObject.therapistId = edpnContext.selectedEditAnswer.therapistId;
            }

            // copy therapyScheduleId
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.therapyScheduleId) {
                finalAnswerObject.therapyScheduleId = edpnContext.selectedEditAnswer.therapyScheduleId;
            }

            // copy companyInsuranceId
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.companyInsuranceId) {
                finalAnswerObject.companyInsuranceId = edpnContext.selectedEditAnswer.companyInsuranceId;
            }

            //copy groupid
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.groupId) {
                finalAnswerObject.groupId = edpnContext.selectedEditAnswer.groupId;
            }

            // need to set the late reasonid here because I believe it is getting removed because of this.
            if (edpnContext.selectedEditAnswer && edpnContext.selectedEditAnswer.lateReasonId) {
                finalAnswerObject.lateReasonId = edpnContext.selectedEditAnswer.lateReasonId;
            }

            //block autosave on completed logs
            if (!isComplete && (edpnContext.selectedEditAnswer?.dpnstatusId == 4 || edpnContext.selectedEditAnswer?.dpnstatusId == 7))
            {
                console.log("blocked autosave since log is complete.");
                setIsAutoSaving(false);
                setAddLogLoading(false);
                return;
            }


            if (isComplete) {
                if (edpnContext.selectedEditAnswer?.dpnstatusId == 6) {
                    finalAnswerObject.dpnstatusId = 4;
                }
                else if (edpnContext.selectedEditAnswer?.dpnstatusId == 4) {
                    finalAnswerObject.dpnstatusId = 4;
                }
                else if (edpnContext.selectedEditAnswer?.dpnstatusId == 2) {
                    finalAnswerObject.dpnstatusId = 7;
                }
                else if (edpnContext.selectedEditAnswer?.dpnstatusId == 7) {
                    finalAnswerObject.dpnstatusId = 7;
                }
            }
            else {
                if (edpnContext.selectedEditAnswer?.dpnstatusId == 2) {
                    finalAnswerObject.dpnstatusId = 2;
                }
                else {
                    finalAnswerObject.dpnstatusId = 6;
                }
            }


            if (selectedReason) {
                finalAnswerObject.tardyReasonId = selectedReason.id;
            }
        }

        globalContext.GetToken().then((token: any) => {

            //test for log overlap
            NoteValidation(token, finalAnswerObject, isComplete).then((overlap) => {

                //add bcba edit reason
                AddComment(token).then(() => {

                    if (overlap) {
                        setIsAutoSaving(false);
                        setAddLogLoading(false);
                    }
                    else {
                        var headers = new Headers();
                        var bearer = "Bearer " + token;
                        headers.append("Authorization", bearer);
                        headers.append("Content-type", "application/json;charset=UTF-8");

                        var options = {
                            method: "POST",
                            headers: headers,
                            body: JSON.stringify(finalAnswerObject)
                        };

                        console.log("final answer object edit:", finalAnswerObject);

                        const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length;
                        const kiloBytes = size / 1024;
                        console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

                        var url = apiConfig.apiEndpoint + "/EDPN/UpdateEDPN";

                        // Timeout setup
                        const controller = new AbortController();
                        const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 seconds

                        fetch(url, { ...options, signal: controller.signal })
                            .then((res) => res.json())
                            .then(
                                (result: APIReturnObject) => {
                                    clearTimeout(timeoutId); // Clear the timeout if successful
                                    console.log("api result:", result);
                                    if (result.status === Status.SUCCESS) {
                                        if (isComplete) {
                                            setSuccessModalActive(true);
                                            console.log("regular save");
                                        }
                                        else {
                                            if (result.message) {
                                                const newLogObject = JSON.parse(result.message);
                                                console.log("Autosaved:", newLogObject);

                                                globalContext.setToastMessage("Autosave Successful");
                                                globalContext.setToastIcon("Success");
                                                globalContext.setToastActive(true);

                                                const oldLogObject = answerObject;


                                                if (oldLogObject && newLogObject && newLogObject.LogId) {
                                                    oldLogObject.logId = newLogObject.LogId;
                                                }

                                                //update logid
                                                setAnswerObject(oldLogObject);
                                            }
                                        }
                                    }
                                    else if (result.status === Status.FAIL) {
                                        globalContext.setNotificationModalMessage("Cannot edit note. " + result.message);
                                        globalContext.setNotificationModalActive(true);
                                    }
                                    else if (result.status === Status.ERROR) {
                                        globalContext.setErrorModalProps({ errorMessage: "Failed to edit note. " + result.message, errorLocation: "/EDPN/UpdateEDPN" });
                                        globalContext.setErrorModalActive(true);
                                    }
                                    setAddLogLoading(false);
                                    setIsAutoSaving(false);
                                },
                                (error) => {
                                    clearTimeout(timeoutId); // Clear the timeout in case of error
                                    if (error.name === "AbortError") {
                                        console.log("Request timed out");
                                        globalContext.setToastMessage("Autosave Failed. Connection timed out.");
                                        globalContext.setToastIcon("Warning");
                                        globalContext.setToastActive(true);
                                    }
                                    else
                                    {
                                        globalContext.setErrorModalProps({
                                            errorMessage: error,
                                            errorLocation: "/EDPN/UpdateEDPN",
                                        });
                                        globalContext.setErrorModalActive(true);
                                    }
                                    
                                    setAddLogLoading(false);
                                    setIsAutoSaving(false);
                                }
                            );
                    }
                });
            });
        });
    }


    function UpdateLogDPNStatus() {
        globalContext.GetToken().then((token: any) => {

            if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.currentUser?.therapistID && changeToPendingChecked) {

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                };

                var url = apiConfig.apiEndpoint + "/EDPN/UpdateLogDPNStatus?id=" + edpnContext.selectedEditAnswer?.logId + "&status=" + 2;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result: APIReturnObject) => {
                            if (result.status === Status.SUCCESS) {
                                UpdateComments();
                            }
                            else if (result.status === Status.FAIL) {
                                globalContext.setNotificationModalMessage("Cannot edit log. " + result.message);
                                globalContext.setNotificationModalActive(true);
                            }
                            else if (result.status === Status.ERROR) {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to edit log. " + result.message, errorLocation: "/EDPN/UpdateLogDPNStatus" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/EDPN/UpdateLogDPNStatus" });
                            globalContext.setErrorModalActive(true);
                        }
                    )

            }
        })
    }

    function AddCommentToLog() {
        globalContext.GetToken().then((token: any) => {

            if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.currentUser && billingDate != null && clientComment.length > 0 && edpnContext.selectedEditAnswer?.logId) {

                var commentObject: ClientComment = {
                    clientId: edpnContext.selectedClient.clientId,
                    billingDate: new Date(new Date(billingDate).toLocaleString() + " +0000"), /*this needs to get changed to current billing date?*/
                    comment: clientComment,
                    createdOn: new Date(new Date().toLocaleString() + " +0000"),
                    createdBy: globalContext.CoreAccount.currentUser.name,
                    refId: edpnContext.selectedEditAnswer?.logId,
                };

                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(commentObject)
                };

                //debug size in kb
                const size = new TextEncoder().encode(JSON.stringify(commentObject)).length
                const kiloBytes = size / 1024;
                console.log("size:" + kiloBytes + " kb ", commentObject);

                var url = apiConfig.apiEndpoint + "/Clients/AddComment";

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result == true) {
                                console.log("success adding comment");
                                if (changeToPendingChecked) {
                                    UpdateLogDPNStatus();
                                }

                                setClientComment("");
                                UpdateComments();
                            }
                            else {
                                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Unknown error.", errorLocation: "/Clients/AddComment" });
                                globalContext.setErrorModalActive(true);
                            }
                        },
                        (error) => {
                            setErrorMessage(error);
                            setErrorModalActive(true);
                        }
                    )

            }
            else if (clientComment.length == 0) {
                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Please be sure to input a comment before adding or sending back for corrections", errorLocation: "/Clients/AddComment" });
                globalContext.setErrorModalActive(true);
            }
            else {
                globalContext.setErrorModalProps({ errorMessage: "Failed to add comment. Unknown error.", errorLocation: "/Clients/AddComment" });
                globalContext.setErrorModalActive(true);
            }
        })
    }

    function HandleOnAddCommentClick() {
        if (changeToPendingChecked) {
            setChangeToPendingModalActive(true);
        }
        else {
            AddCommentToLog();
        }

    }


    function UpdateDiableAddLogButton() {

        //loop through all answers, if one answer is blank, set allanswerscomplete to false
        let AllAnswersComplete = true;
        if (answerObject && answerObject.answers) {

            //only check required questions
            var RequiredQuestions = flatQuestions.filter(x => x.type != "multirbt"
                && x.type != "bcbapresent"
                && x.type != "bcbaname"
                && x.type != "abaname"
                && x.type != "abapresent"
                && x.type != "peoplename"
                && x.type != "peoplepresent"
                && x.type != "text_abnormal_behaviors"
                && x.type != "text_injury"
                && x.type != "text_participate"
                && x.type != "bool_default_false_abnormal_behaviors"
                && x.type != "bool_default_false_injury"
                && x.type != "bool_default_false_participate"
            );
            //rbt present
            var RBTPresentQuestions = flatQuestions.filter(x => x.type == "rbt");

            answerObject.answers.filter(a =>
                RequiredQuestions.find(y => y.questionId == a.questionId)
                && (!a.choiceValue || a.choiceValue == -1) 
                && (!a.numberValue)
                && (!a.textValue || a.textValue.length == 0 || RBTPresentQuestions.find(z => z.questionId == a.questionId && a.textValue == "-1"))
                && (a.boolValue == null)
            ).forEach(z => AllAnswersComplete = false);
        }

        //logic for add log button
        setEditLogButtonDisabled(
            !answerObject ||
            !answerObject.answers ||
            answerObject.answers.length == 0 ||
            !AllAnswersComplete ||
            timeIn == null ||
            timeOut == null ||
            (globalContext.CoreAccount.currentUser?.bcba == true && bcbaEditReason.length == 0 && edpnContext.selectedEditAnswer?.therapistId != globalContext.CoreAccount.currentUser?.therapistID)
        );
    }

    const SaveNoteButton = () => {

        //hide save button when log is pending
        if (edpnContext.selectedEditAnswer?.dpnstatusId != 2 && edpnContext.selectedEditAnswer?.dpnstatusId != 7) {
            if (saveNoteLoading) {
                return (
                    <button disabled={true} className="m-auto mr-2 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-purple-800">
                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                        <span className="drop-shadow align-middle pl-1">Processing</span>
                    </button>
                );
            }
            else {
                return (<button onClick={() => SaveNoteCheck()} className={"bg-purple m-auto mr-2 w-full w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"}><span className="drop-shadow">Save Note</span></button>)
            }
        }
        else {
            return (
                <></>
            )
        }
        
    }

    const SubmitNoteButton = () => {

        if (saveNoteLoading || isAutosaving) {
            return (
                <button disabled={true} className="m-auto w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacblue-800">
                    <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                    <span className="drop-shadow align-middle pl-1">Processing</span>
                </button>
            );
        }
        else {
            return (<button onClick={() => PreSubmissionGrammarCheck()} disabled={editLogButtonDisabled} className={"bg-lacblue m-auto w-full w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 " + (editLogButtonDisabled ? 'bg-lacblue-800 hover:bg-lacblue-800' : '')}><span className="drop-shadow">Submit Note</span></button>)
        }
    }

    async function AddComment(token: string) {

        if (edpnContext && edpnContext.selectedClient && edpnContext.selectedClient.clientId && globalContext.CoreAccount.currentUser && bcbaEditReason && bcbaEditReason.length > 0 && edpnContext.selectedEditAnswer?.logId) {

            var commentObject: ClientComment = {
                clientId: edpnContext.selectedClient.clientId,
                billingDate: new Date(new Date().toLocaleString() + " +0000"),
                comment: bcbaEditReason,
                createdOn: new Date(new Date().toLocaleString() + " +0000"),
                createdBy: globalContext.CoreAccount.currentUser.name,
                refId: edpnContext.selectedEditAnswer?.logId,
            };

            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(commentObject)
            };

            //debug size in kb
            const size = new TextEncoder().encode(JSON.stringify(commentObject)).length
            const kiloBytes = size / 1024;
            console.log("size:" + kiloBytes + " kb ", commentObject);

            var url = apiConfig.apiEndpoint + "/Clients/AddComment";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result == true) {
                            console.log("success adding comment");
                            return true;
                        }
                        else {
                            globalContext.setErrorModalProps({ errorMessage: "Failed to add edit reason. Unknown error.", errorLocation: "/Clients/AddComment" });
                            globalContext.setErrorModalActive(true);
                            return false;
                        }
                    },
                    (error) => {
                        setErrorMessage(error);
                        setErrorModalActive(true);
                        return false;

                    }
                )

        }
        else {
            return true;
        }
    }

    function TimeInValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeInText = timeInText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeInText, "h:mm a", new Date());
        var d2 = parse(tempTimeInText, "h:mma", new Date());
        var d3 = parse(tempTimeInText, "hmma", new Date());
        var d4 = parse(tempTimeInText, "h a", new Date());
        var d5 = parse(tempTimeInText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                console.log("timein:", dformatted);

                //autocorrect
                if (tempTimeInText != dformatted) {
                    setTimeInText(dformatted);
                }

                //check for negative time
                if ((timeOut && isBefore(d, timeOut)) || (SiblingDate && isBefore(d, SiblingDate))) {
                    //update time in
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else if (!timeOut) {
                    setTimeIn(d);

                    //validate time out, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeOutValidateTime(false, d);
                    }
                }
                else {
                    setTimeIn(null);
                }

            }
        }
        else {
            setTimeIn(null);
        }
    }

    function TimeOutValidateTime(ValidateSibling: boolean, SiblingDate: Date | null) {
        //validates time, and autocorrects format if needed.

        //could not be bothered to figure out the regex for this.
        //this runs 5 date parses, and if any of them parse, we know its good.

        var tempTimeOutText = timeOutText.replace(/\s+/g, '').trim()

        var d1 = parse(tempTimeOutText, "h:mm a", new Date());
        var d2 = parse(tempTimeOutText, "h:mma", new Date());
        var d3 = parse(tempTimeOutText, "hmma", new Date());
        var d4 = parse(tempTimeOutText, "h a", new Date());
        var d5 = parse(tempTimeOutText, "ha", new Date());

        if (d1.getTime() || d2.getTime() || d3.getTime() || d4.getTime() || d5.getTime()) {
            var d;

            if (d1.getTime()) {
                d = d1;
            }
            else if (d2.getTime()) {
                d = d2;
            }
            else if (d3.getTime()) {
                d = d3;
            }
            else if (d4.getTime()) {
                d = d4;
            }
            else if (d5.getTime()) {
                d = d5;
            }

            if (d) {
                var dformatted = format(d, "h:mm aa");

                //autocorrect
                if (tempTimeOutText != dformatted) {
                    setTimeOutText(dformatted);
                }

                //check for negative time
                if ((timeIn && isAfter(d, timeIn)) || (SiblingDate && isAfter(d, SiblingDate))) {
                    //update time out
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else if (!timeIn) {
                    setTimeOut(d);

                    //validate time in, incase our update fixes the validation issue
                    if (ValidateSibling) {
                        TimeInValidateTime(false, d);
                    }
                }
                else {

                    setTimeOut(null);
                }
            }
        }
        else {
            setTimeOut(null);
        }
    }

    function SaveEditReason(input: string) {
        setBCBAEditReason(input);
    }

    async function OnChangeGroupID(input: SingleValue<{ value: string; label: string | null; }>) {
        if (input) {

            try {

                edpnContext.setGroupEditNavigating(true);
                navigate("/edpn");

                const log = await GetLogByID(input.value);

                const client = await GetClientByID(log.clientId);

                edpnContext.setSelectedEditAnswer(log);
                edpnContext.setSelectedClient(client);
                edpnContext.setIsViewOnly(false);

                if (groupLogs) {
                    var newlog = groupLogs.find(x => x.logId == log.logId);
                    if (newlog) {
                        setSelectedGroupLog(newlog);
                    }
                }

                navigate("/edpn/approvals/editlog");
                edpnContext.setGroupEditNavigating(false);

            } catch (error) {
                console.error("Error fetching log or client:", error);
                edpnContext.setGroupEditNavigating(false);
                // Handle the error appropriately, perhaps show a notification to the user
            }
        }
    }

    // This is an asynchronous function that retrieves a client object by ID
    async function GetClientByID(id: number) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Clients/GetClientByID?clientid=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Clients/GetClientByID" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    async function GetLogByID(id: string) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/EDPN/GetLog?id=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/EDPN/GetLog" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    const LogEditView = () => {
        if (edpnContext.selectedEditAnswer) {
            return <EDPNViewLogCard Log={edpnContext.selectedEditAnswer} Therapists={globalContext.therapists} SetCredentialsModalActive={setCredentialsModalActive} />
        }
        else {
            return <></>
        }
    }

    if (edpnContext.selectedClient && !commentsLoading) {
        return (
            <div className="ml-auto mr-auto p-2">
                <div className="grid grid-cols-2 h-fit mt-0 mb-auto">

                    <div onClick={NavigateBack} className="my-2 mb-8 flex items-center justify-center w-40 cursor-pointer col-span-2">
                        <div className="flex-1">
                            <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                        </div>
                        <span className="text-xl w-auto ml-3">Back to Logs</span>
                        <div className="flex-1"></div>
                    </div>

                    


                    <ProfilePicture isEditable={false} Client={edpnContext.selectedClient} setProfilePictureModalActive={setProfilePictureModalActive} setSelectedClientForModal={setSelectedClientForModal} />

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.fullName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{new Date(edpnContext.selectedClient.dob).toLocaleDateString()}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Insurance Provider:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.insuranceName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">RBT Required:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.rbtrequired == null ? '' : edpnContext.selectedClient.rbtrequired ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Telehealth Allowed:</span></td>
                                <td><span className="text-bold ml-2">{edpnContext.selectedClient.telehealthAllowed == null ? '' : edpnContext.selectedClient.telehealthAllowed ? 'Yes' : 'No'}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Billing Day Schedule:</span></td>
                                <td><span className="text-bold ml-2">{billingDaySchedule ? new Date(billingDaySchedule.startTime).toLocaleTimeString() + "-" + new Date(billingDaySchedule.endTime).toLocaleTimeString() : "N/A"}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="col-span-2 mt-8 xl:inline hidden">
                    <span className="text-lg font-bold">Original Log:</span>
                    <LogEditView />
                </div>

                {/* Comments go here*/}
                <div className="grid w-full mt-5 xl:col-span-12 col-span-12">

                    <div className="w-full border-2">
                        <span className="font-bold">Comments:</span>
                        <table className="border-separate border-spacing-3">
                            <tbody>
                                {clientComments ? clientComments.filter(x => x.refId == edpnContext.selectedEditAnswer?.logId || x.refId == null).map(comment => <tr key={comment.dpncommentId}><td>[{comment.createdOn ? format(new Date(comment.createdOn), 'M/d/yy') : ""}]</td><td>{comment.createdBy}: </td><td>{comment.comment}</td></tr>) : <></>}
                                {clientComments.filter(x => x.refId == edpnContext.selectedEditAnswer?.logId || x.refId == null).length == 0 && <tr><td className="text-rose">No Comments.</td></tr>}

                            </tbody>
                        </table>
                    </div>
                    <label className="block font-bold mt-2">
                        Add Comment:
                    </label>
                    <input maxLength={250} autoComplete="off" type="text" value={clientComment} onChange={(event) => setClientComment(event.target.value)} className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />

                    <div>
                        <div className="float-left">
                            <button onClick={HandleOnAddCommentClick} className={"bg-lacblue m-auto mt-2 w-[8rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacblue-600 noprint"}><span className="drop-shadow">Add Comment</span></button>
                        </div>

                        <ProtectedComponent RequiredRoles={['EDPN.Corrections']}>
                            <div className={"pl-10 float-left mt-2 " + (edpnContext.selectedEditAnswer?.dpnstatusId == 4 ? "normal" : "hidden")} >
                                <span className="float-left">
                                    <strong>Send back for Correction: </strong><input type="checkbox" onChange={event => setChangeToPendingChecked(event.target.checked)} />
                                </span>
                            </div>
                        </ProtectedComponent>
                    </div>
                    
                </div>

                <div className="grid gap-8 grid-cols-12 xl:w-[40rem] w-full m-auto mt-4 mb-8">

                    <div className={"w-full col-span-12 " + (edpnContext.selectedEditAnswer?.groupId ? "" : "hidden")}>
                        <label className="block font-bold" htmlFor="centers">
                            Group Learner Selection:
                        </label>
                        <Select isMulti={false} isSearchable={false} closeMenuOnSelect={true} onChange={(event) => OnChangeGroupID(event)} value={selectedGroupLog ? { value: String(selectedGroupLog.logId), label: selectedGroupLog.clientName } : null} options={groupLogs && groupLogs.length > 0 ? groupLogs.map((log) => ({ value: String(log.logId), label: log.clientName})) : undefined} />
                    </div>

                    <div className="w-full xl:col-span-12 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Service Type:
                        </label>
                        <Select isSearchable={false} options={FilteredCPTCodes ? FilteredCPTCodes : undefined} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={edpnContext.setSelectedCPTCode} value={edpnContext.selectedCPTCode} />
                    </div>

                    <div className="w-full xl:col-span-6 col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Place of Service:
                        </label>
                        <Select isSearchable={false} options={edpnContext.servicePlaces ? edpnContext.servicePlaces : undefined} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={edpnContext.setSelectedServicePlace} value={edpnContext.selectedServicePlace} />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold">
                            Service Date:
                        </label>
                        <DatePicker
                            value={billingDate}
                            disabled={true}
                            disableFuture={true}
                            minDate={new Date()}
                            onClose={() => EditNote(false)}
                            onChange={(newValue) => {
                                setBillingDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time In:
                        </label>
                        <input onBlur={() => { TimeInValidateTime(true, null); edpnContext.setAutosaveFlag(true); }} onChange={event => setTimeInText(event.target.value)} value={timeInText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeInText.length > 0 && !timeIn ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full m-auto xl:col-span-4 col-span-12">
                        <label className="block font-bold" htmlFor="search">
                            Time Out:
                        </label>
                        <input onBlur={() => { TimeOutValidateTime(true, null); edpnContext.setAutosaveFlag(true); }} onChange={event => setTimeOutText(event.target.value)} value={timeOutText} autoComplete="off" type="text" className={"outline-none h-[38px]  text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5 focus:border-2 " + (timeOutText.length > 0 && !timeOut ? 'border-2 border-rose' : 'border border-gray-300')} placeholder="hh:mm aa" />
                    </div>

                    <div className="w-full col-span-12">
                        <label className="block font-bold" htmlFor="centers">
                            Were there missed hours this session?:
                        </label>
                        <Select isClearable={true} isSearchable={false} options={edpnContext.absentReasons ? edpnContext.absentReasons?.filter(x => x.absent == false) : undefined} onBlur={() => edpnContext.setAutosaveFlag(true)} onChange={setSelectedReason} value={selectedReason} />
                    </div>

                    <div className={"w-full col-span-12 " + (!globalContext.CoreAccount.currentUser?.bcba || edpnContext.selectedEditAnswer?.therapistId == globalContext.CoreAccount.currentUser?.therapistID ? 'hidden' : '')}>
                        <label className="block font-bold">
                            Edit Reason:
                        </label>
                        <input defaultValue={bcbaEditReason} onChange={(event) => SaveEditReason(event.target.value)} autoComplete="off" type="text" className="outline-none h-[38px] border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="w-full h-fit col-span-12">
                        {filteredQuestions && <EDPNQuestionNode EDPNQuestions={filteredQuestions} EDPNParentQuestion={null} setAnswerObject={setAnswerObject} answerObject={answerObject} Therapists={globalContext.therapists} ResetAnswerObject={ResetAnswerObject} AutoSelectQuestionStack={questionStack} FlatQuestions={flatQuestions} />}
                    </div>


                    <div className="w-full col-span-12 flex justify-between">
                        <button onClick={NavigateBack} className="bg-rose w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600 flex-grow-0 mr-2">
                            <span className="drop-shadow">Cancel</span>
                        </button>
                        <div className="flex-grow"></div>
                        <SubmitNoteButton />
                    </div>

                </div>

                <PopperGrammarBoxes GrammarSet={grammarContext.grammarSets} makeCorrection={grammarContext.MakeCorrection}></PopperGrammarBoxes>

                <ModalNotification Title={"Warning!"} Icon={"Warning"} Body={"Less than 2 Antecedent Strategies are selected. Unable to submit."} Active={antecedentConfirmationModalActive} setActive={setAntecedentConfirmationModalActive} OnModalClose={function noop() { }} />
                <ModalConfirm Message={submitNoteConfirmationMessage} Active={submitNoteConfirmationModalActive} setActive={setSubmitNoteConfirmationModalActive} OnConfirm={() => EditNote(true)} />
                <ModalConfirm Message={saveNoteConfirmationMessage} Active={saveNoteConfirmationModalActive} setActive={setSaveNoteConfirmationModalActive} OnConfirm={() => EditNote(false)} />
                <ModalConfirm Message={"There are possible grammar mistakes in one of the inputs. Are you sure you want to continue?"} Active={grammarMistakeModalActive} setActive={setGrammarMistakeModalActive} OnConfirm={() => AntecedentStrategiesCheck()} />

                <ModalNotification Title={"Success!"} Body={"Note for " + edpnContext.selectedClient.fullName + " successfully edited."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={SuccessModalClose} />
                <ModalNotification Title={"Error!"} Body={"Contact IT if this error persists. Error Message: " + errorMessage} Active={errorModalActive} Icon={"Warning"} setActive={setErrorModalActive} OnModalClose={function noop() {} } />
                <ProfilePictureModal Client={selectedClientForModal} Active={profilePictureModalActive} setActive={setProfilePictureModalActive} OnModalClose={ProfilePictureOnCLose} />
                <ModalNotification Title={"Warning!"} Body={"Failed to edit note. " + overlapMessage} Active={overlapModalActive} Icon={"Warning"} setActive={setOverlapModalActive} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Warning: Note cannot be edited."} Body={futureMessage} Active={futureModalActive} setActive={setFutureModalActive} Icon={"Warning"} OnModalClose={function noop() { }} />
                <ModalNotification Title={"Summary"} Body={edpnContext.exampleMessage} Active={edpnContext.exampleModalActive} Icon={"Info"} setActive={edpnContext.setExampleModalActive} OnModalClose={function noop() { }} />
                <ModalConfirm Message={"Are you sure you want to send this note back for corrections?"} Active={changeToPendingModalActive} setActive={setChangeToPendingModalActive} OnConfirm={() => AddCommentToLog()} />

            </div>

        );
    }
    else {
        return (
            <div className="w-full h-auto flex">
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>
        );
    }
    
};




export function EDPNEditLog() {

    const authRequest = {
        ...loginRequest
    };


    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['EDPN.Write', 'EDPN.Approve']}>
                    <GrammarContextProvider>
                        <EDPNEditLogContent />
                    </GrammarContextProvider>
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
      )
};