// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../authConfig";

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from '../utils/GlobalContext';
import { EDPNContext } from "../pages/edpn/EDPNContext";
import { ECLContext } from "../pages/ecl/ECLContext";
import { addDays, format, isAfter, isBefore, isSameDay, isYesterday, parse, parseISO, set, subDays } from "date-fns";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';
import Select, { SingleValue } from "react-select";

import { Client, TherapistSmallObject, CPTCode, ChoiceOption, VwLateEcldpnreason, EDPNAnswerObject, ECLAnswerObject, APIReturnObject, Status, AbsentReason } from "../utils/Definitions";

interface Props {
    isActive: boolean,
    setActive: any,
    isEDPN: boolean,
    cptCodes: CPTCode[] | null,
    clients: Client[],
}

export const ModalAddLateNote: React.FC<Props> = (props) => {
    const globalContext = useContext(GlobalContext);
    const edpnContext = useContext(EDPNContext);
    const eclContext = useContext(ECLContext)

    const [confirmButtonActive, setConfirmButtonActive] = useState<Boolean>(false);
    const [isSaving, setIsSaving] = useState<Boolean>(false);

    const [clients, setClients] = useState<Client[]>([]);
    const [dateOfService, setDateOfService] = useState<Date | null>(new Date());

    const [clinicians, setClinicians] = useState<TherapistSmallObject[]>([]);
    const [filteredClinicians, setFilteredClinicians] = useState<TherapistSmallObject[]>([]);
    const [selectedClinician, setSelectedClinician] = useState<ChoiceOption | null>(null);
    const [cptCodes, setCptCodes] = useState<CPTCode[] | null>();
    const [FilteredCPTCodes, setFilteredCPTCodes] = useState<CPTCode[] | null>();
    const [selectedCptCode, setSelectedCptCode] = useState<CPTCode | null>(null);
    const [selectedLearner, setSelectedLearner] = useState<ChoiceOption | null>(null);
    const [selectedReason, setSelectedReason] = useState<ChoiceOption | null>(null);
    const [selectedLearnerPodId, setSelectedLearnerPodId] = useState<number | null>(null);

    const [isLateAbsentNote, setIsLateAbsentNote] = useState<boolean>(false);
    const [selectedAbsentReason, setSelectedAbsentReason] = useState<AbsentReason | null>();

    var minimumDate = new Date();


    const [lateReasons, setLateReasons] = useState<VwLateEcldpnreason[]>([]);

    const [answerObject, setAnswerObject] = useState<EDPNAnswerObject | null>(null);

    const [eclAnswerObject, setEclAnswerObject] = useState<ECLAnswerObject | null>(null);

    useEffect(() => {
        GetCliniciansForDropdown();
        GetReasonsForDropdown();
        if (!props.isEDPN) {
            UpdateCPTCodes();
        }
    }, []);

    useEffect(() => {
        if (clinicians) {
            var tempFilteredClinicians = clinicians.filter(x => x.therapistId !== globalContext.CoreAccount.therapistData?.therapistId);
            setFilteredClinicians(tempFilteredClinicians);
        }
    }, [clinicians]);

    useEffect(() => {
        if (!props.isEDPN) {
            if (cptCodes) {
                // filter CPTcodes by state
                var tempcodes: CPTCode[] = cptCodes.filter(x => x.value != "H0032" && x.value != "G9012" && x.value != "97152");

                //add michigan codes
                if ((eclContext.selectedCenter && eclContext.selectedCenter.state == "MI") || (eclContext.selectedClient && eclContext.selectedClient.isMimedicaid)) {
                    cptCodes.filter(x => x.value == "H0032" || x.value == "97152").forEach(x => tempcodes.push(x));
                }

                //add iowa codes
                if ((eclContext.selectedCenter && eclContext.selectedCenter.state == "IA") || (eclContext.selectedClient && eclContext.selectedClient.isIamedicaid)) {
                    cptCodes.filter(x => x.value == "G9012" || x.value == "97152").forEach(x => tempcodes.push(x));
                }

                setFilteredCPTCodes(tempcodes);
            }
        }
        else {
            if (props.cptCodes) {
                // filter out 97156
                var tempcodes: CPTCode[] = props.cptCodes.filter(x => x.value != "97156");

                //add 97156 for IOWA and EAST MOLINE ONLY
                if (edpnContext.selectedCenter && (edpnContext.selectedCenter.state == "IA" || edpnContext.selectedCenter.centerId == 48)) {
                    props.cptCodes.filter(x => x.value == "97156").forEach(x => tempcodes.push(x));
                }

                setFilteredCPTCodes(tempcodes);
            }
        }

    }, [props.cptCodes, cptCodes, eclContext.selectedCenter, edpnContext.selectedCenter]); 

    useEffect(() => {
        if (props.clients) {
            setClients(props.clients);
        }

    }, [props.clients]); 

    useEffect(() => {
        ValidateInformation();

    }, [dateOfService, selectedClinician, selectedCptCode, selectedLearner, selectedReason, isLateAbsentNote, selectedAbsentReason]);

    useEffect(() => {
        if (props.isActive) {
            if (props.isEDPN) {
                ResetAnswerObject();

            }
            else {
                ResetECLAnswerObject();
            }
        }
    })

    // mapping of answers to codes
    const cptCodeECLMinAnswers = [
        {
            cptCode: '97151', answers: [
                /*{ // The Greate 'Choose'
                    answerId: null,
                    questionId: 3,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },*/
                { // Other People Present
                    answerId: null,
                    questionId: 10,
                    multiIndex: null,
                    logId: null,
                    textValue: '',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 11,
                    multiIndex: null,
                    logId: null,
                    textValue: '',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 61,
                    multiIndex: null,
                    logId: null,
                    textValue: '',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { //ABA Clinician
                    answerId: null,
                    questionId: 66,
                    multiIndex: null,
                    logId: null,
                    textValue: '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Goals addressed
                    answerId: null,
                    questionId: 83,
                    multiIndex: 0,
                    logId: null,
                    textValue: ' ',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                }
                
            ]
        },
        {
            cptCode: '97155', answers: [
                { //ABA Clinician
                answerId: null,
                questionId: 25,
                multiIndex: null,
                logId: null,
                textValue: '-1',
                numberValue: null,
                boolValue: true,
                choiceValue: null,
                log: null,
                question: null
            },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 81,
                    multiIndex: null,
                    logId: null,
                    textValue: '',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 27,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present bool
                    answerId: null,
                    questionId: 65,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: false,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Goals
                    answerId: null,
                    questionId: 26,
                    multiIndex: 0,
                    logId: null,
                    textValue: ' ',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                }] },
        {
            cptCode: '97156', answers: [
                { //ABA Clinician
                answerId: null,
                questionId: 257,
                multiIndex: null,
                logId: null,
                textValue: null,
                numberValue: null,
                boolValue: true,
                choiceValue: null,
                log: null,
                question: null
                },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 258,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 259,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present bool
                    answerId: null,
                    questionId: 44,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: false,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present
                    answerId: null,
                    questionId: 251,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: -1,
                    log: null,
                    question: null
                },
                {
                    answerId: null,
                    questionId: 138,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                {
                    answerId: null,
                    questionId: 43,
                    multiIndex: 0,
                    logId: null,
                    textValue: ' ',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                {
                    answerId: null,
                    questionId: 45,
                    multiIndex: 0,
                    logId: null,
                    textValue: ' ',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },

            ]
        },
        {
            cptCode: '97152', answers: [
                { //ABA Clinician
                    answerId: null,
                    questionId: 111,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: true,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 112,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 113,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present bool
                    answerId: null,
                    questionId: 114,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: false,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present
                    answerId: null,
                    questionId: 115,
                    multiIndex: null,
                    logId: null,
                    textValue: '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                {
                    answerId: null,
                    questionId: 116,
                    multiIndex: 0,
                    logId: null,
                    textValue: ' ',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                }]
        },
        {
            cptCode: 'H0032', answers: [
                { //ABA Clinician
                    answerId: null,
                    questionId: 111,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: true,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 112,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 113,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present bool
                    answerId: null,
                    questionId: 114,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: false,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present
                    answerId: null,
                    questionId: 115,
                    multiIndex: null,
                    logId: null,
                    textValue: '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                {
                    answerId: null,
                    questionId: 116,
                    multiIndex: null,
                    logId: null,
                    textValue: '',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                }]
        }

    ]

    function CreateEclAnswerObject(): ECLAnswerObject {
        var answerObject: ECLAnswerObject = {
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: Number(selectedLearner?.value),
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: Number(selectedClinician?.value),
            dpnstatusId: 2,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            lateReasonId: null,
        }

        return answerObject;
    }

    function CreateAnswerObject(): EDPNAnswerObject {
        var answerObject: EDPNAnswerObject = {
            answers: [
                { //ABA Clinician
                    answerId: null,
                    questionId: 238,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: true,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { //ABA Clinician Present
                    answerId: null,
                    questionId: 228,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: selectedClinician ? Number(selectedClinician.value) : -1,
                    log: null,
                    question: null
                },
                { // Additional ABA Clinician Name
                    answerId: null,
                    questionId: 229,
                    multiIndex: null,
                    logId: null,
                    textValue: '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present bool
                    answerId: null,
                    questionId: 240,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: false,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // Other People Present
                    answerId: null,
                    questionId: 230,
                    multiIndex: null,
                    logId: null,
                    textValue: null,
                    numberValue: null,
                    boolValue: null,
                    choiceValue: -1,
                    log: null,
                    question: null
                },
                { // Other People Present Name
                    answerId: null,
                    questionId: 231,
                    multiIndex: null,
                    logId: null,
                    textValue: '-1',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                },
                { // group summary
                    answerId: null,
                    questionId: 250,
                    multiIndex: null,
                    logId: null,
                    textValue: '',
                    numberValue: null,
                    boolValue: null,
                    choiceValue: null,
                    log: null,
                    question: null
                }
            ],
            logId: null,
            startTime: dateOfService ? new Date(dateOfService?.toLocaleString() + " +0000") : null,
            endTime: dateOfService ? new Date(dateOfService?.toLocaleString() + " +0000") : null,
            createdDate: new Date(new Date().toLocaleString() + " +0000"),
            approvedDate: null,
            clientId: Number(selectedLearner?.value),
            cptcode: selectedCptCode?.value ?? null,
            narrative: null,
            billingDate: dateOfService,
            therapistId: globalContext.CoreAccount.therapistData?.therapistId ?? null,
            dpnstatusId: 2,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: edpnContext.selectedServicePlace ? Number(edpnContext.selectedServicePlace.value) : null,
            centerId: edpnContext.selectedCenter ? edpnContext.selectedCenter.centerId : null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: edpnContext.clients ? (edpnContext.clients.find(x => x.clientId === Number(selectedLearner?.value))?.podId ?? null) : null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            absent: null,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
            lateReasonId: null,
        };

        return answerObject;
    }



    function UpdateCPTCodes() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Admin/GetCPTCodes?app=ecl";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setCptCodes(result);
                        
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Admin/GetCPTCodes" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }


    function GetCliniciansForDropdown() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/Centers/GetAllClinicians";

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setClinicians(result);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllClinicians" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        });
    }

    function GetReasonsForDropdown() {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
                method: "GET",
                headers: headers
            };

            var url = apiConfig.apiEndpoint + "/EDPN/GetLateNoteReasons?isEDPN=" + props.isEDPN;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setLateReasons(result);
                        //setLoading(false);
                    },
                    (error) => {
                        //setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Centers/GetAllClinicians" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        });
    }

    function OnCptCodeChange(input: SingleValue<ChoiceOption>) {

        if (FilteredCPTCodes) {

            var foundCptCode = FilteredCPTCodes?.find(z => String(z.value) === String(input?.value));
            var temp: ChoiceOption;

            if (foundCptCode) {
                temp = ({ value: String(foundCptCode.value), label: foundCptCode.label })
                setSelectedCptCode(temp);
            }
        }

    }

    function OnClinicianChange(input: SingleValue<ChoiceOption>) {

        if (clinicians) {

            var foundClinician = clinicians?.find(z => String(z.therapistId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundClinician) {
                temp = ({ value: String(foundClinician.therapistId), label: foundClinician.employeeName })
                setSelectedClinician(temp);
            }
        }
    }

    function OnSelectedClientChange(input: SingleValue<ChoiceOption>) {

        if (props.clients) {

            var foundClinician = props.clients?.find(z => String(z.clientId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundClinician) {
                temp = ({ value: String(foundClinician.clientId), label: foundClinician.fullName })
                setSelectedLearner(temp);
                setSelectedLearnerPodId(foundClinician.podId);
            }
        }
    }

    function OnSelectedReasonChange(input: SingleValue<ChoiceOption>) {

        if (lateReasons) {

            var foundReason = lateReasons?.find(z => String(z.reasonId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundReason) {
                temp = ({ value: String(foundReason.reasonId), label: foundReason.reason })
                setSelectedReason(temp);
            }
        }
    }

    function ValidateInformation() {
        const tomorrow = new Date(); // Start with the current date
        tomorrow.setDate(tomorrow.getDate() + 1); // Move to tomorrow
        tomorrow.setHours(0, 0, 0, 0); // Set time to 12:00 AM (midnight)

        if (
            dateOfService &&
            minimumDate &&
            dateOfService >= minimumDate && dateOfService <= tomorrow &&
            selectedClinician &&
            selectedCptCode &&
            selectedLearner &&
            selectedReason &&
            (isLateAbsentNote ? selectedAbsentReason : true)
        ) {
            setConfirmButtonActive(true);
        } else {
            setConfirmButtonActive(false);
        }
    }

    async function LogOverlap(token: string, eclanswerobject: ECLAnswerObject | null) {

        if (eclanswerobject) {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(eclanswerobject)
            };

            const response = await fetch(apiConfig.apiEndpoint + "/ECL/InsertLogCheck", options);
            var errorText: string = await response.json();

            if (errorText.length > 0) {
                //error!

                return true;
            }
            else {
                //no error, continue
                return false;
            }
        }
        else {
            return true;
        }
    }


    //Lord have mercy on me.

    function ResetECLAnswerObject() {
        setEclAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: Number(selectedLearner?.value),
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: Number(selectedClinician?.value),
            dpnstatusId: 2,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            lateReasonId: null,
        })
    }

    function ResetAnswerObject() {

        setAnswerObject({
            answers: [],
            logId: null,
            startTime: null,
            endTime: null,
            createdDate: null,
            approvedDate: null,
            clientId: null,
            cptcode: null,
            narrative: null,
            billingDate: null,
            therapistId: null,
            dpnstatusId: null,
            active: true,
            harvested: false,
            client: null,
            dpnstatus: null,
            therapist: null,
            ecldpnanswers: null,
            placeOfServiceId: null,
            centerId: null,
            updatedOn: null,
            updatedBy: null,
            approvedById: null,
            podId: null,
            clientName: null,
            comments: null,
            modeOfServiceId: null,
            isIamedicaid: null,
            isIlmedicaid: null,
            isInmedicaid: null,
            isMimedicaid: null,
            logSetId: null,
            timezone: null,
            showApproveButton: null,
            absent: false,
            absentReasonId: null,
            tardyReasonId: null,
            tardyReason: null,
            therapyScheduleId: null,
            therapySchedule: null,
            companyInsuranceId: null,
            antecendant: null,
            bcbaname: null,
            injury: null,
            scheduleDuration: null,
            dpnactualDuration: null,
            groupId: null,
            lateReasonId: null,
        })

    }

    function AddEclLog() {

        //set created date
        setIsSaving(true);
        setConfirmButtonActive(false);

        var finalAnswerObject = eclAnswerObject;

        if (eclAnswerObject && finalAnswerObject) {

            if (selectedCptCode && selectedCptCode.value) {
                finalAnswerObject.cptcode = selectedCptCode.value;

                //Set the answer value based on the cpt code that is chosen
                var answers = cptCodeECLMinAnswers.find(x => x.cptCode == selectedCptCode.value);
                if (answers) {
                    finalAnswerObject.answers = answers?.answers;
                }

            }

            //set dates
            if (dateOfService) {
                //forced timezone to UTC so it saves in database correctly.
                finalAnswerObject.createdDate = new Date(new Date().toLocaleString() + " +0000");
                finalAnswerObject.billingDate = new Date(dateOfService.toLocaleString() + " +0000")
                finalAnswerObject.startTime = new Date(dateOfService.toLocaleString() + " +0000")
                finalAnswerObject.endTime = new Date(dateOfService.toLocaleString() + " +0000")

                console.log("finalAnswerObject.createdDate:", finalAnswerObject.createdDate);
                console.log("finalAnswerObject.billingDate:", finalAnswerObject.billingDate);
                console.log("finalAnswerObject.startTime:", finalAnswerObject.startTime);
                console.log("finalAnswerObject.endTime:", finalAnswerObject.endTime);
            }

            if (eclContext.selectedCenter) {
                finalAnswerObject.centerId = eclContext.selectedCenter.centerId;
            }

            if (selectedLearner) {
                finalAnswerObject.clientId = Number(selectedLearner.value);
            }

            //set podid
            finalAnswerObject.podId = selectedLearnerPodId;

            //set LateReasonID
            if (selectedReason) {
                finalAnswerObject.lateReasonId = Number(selectedReason.value);
            }

            if (selectedClinician) {
                finalAnswerObject.therapistId = Number(selectedClinician.value);
            }

            finalAnswerObject.dpnstatusId = 4;


        }

        //add log
        globalContext.GetToken().then((token: any) => {

            //test for log overlap
            LogOverlap(token, finalAnswerObject).then((overlap) => {

                if (overlap) {
                    setIsSaving(false);
                    CancelModal();
                }
                else {
                    //add log to database
                    var headers = new Headers();
                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);
                    headers.append("Content-type", "application/json;charset=UTF-8");

                    var options = {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify(finalAnswerObject)
                    };

                    //debug size in kb
                    const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length
                    const kiloBytes = size / 1024;
                    console.log("size:" + kiloBytes + " kb ", finalAnswerObject);

                    var url = apiConfig.apiEndpoint + "/ECL/InsertLog";

                    fetch(url, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                if (result == true) {
                                    setIsSaving(false);
                                    CancelModal();
                                    
                                    globalContext.setToastMessage("Late Note Added");
                                    globalContext.setToastIcon("Success");
                                    globalContext.setToastActive(true);
                                }
                                else {
                                    setIsSaving(false);
                                    CancelModal();
                                    globalContext.setErrorModalProps({ errorMessage: "Failed to insert log. Unknown error.", errorLocation: "/ECL/InsertLog" });
                                    globalContext.setErrorModalActive(true);
                                }
                            },
                            (error) => {
                                CancelModal();
                                setIsSaving(false);
                                globalContext.setNotificationModalMessage("Cannot create Late note. " + error.message);
                                globalContext.setNotificationModalActive(true);
                            }
                        )
                }

            })
        })
    }

    function AddEDPNNote() {

        setIsSaving(true);
        setConfirmButtonActive(false);

        var finalAnswerObject = CreateAnswerObject();

        if (answerObject && finalAnswerObject) {
            if (selectedCptCode) {
                finalAnswerObject.cptcode = selectedCptCode.value;
            }

            if (selectedClinician) {
                finalAnswerObject.therapistId = Number(selectedClinician.value);
            }

            if (!finalAnswerObject.createdDate) {
                finalAnswerObject.createdDate = new Date(new Date().toLocaleString() + " +0000");
            }

            if (!finalAnswerObject.billingDate) {
                finalAnswerObject.billingDate = new Date();
            }

            if (!finalAnswerObject.startTime && !isLateAbsentNote) {
                finalAnswerObject.startTime = new Date(dateOfService?.toLocaleString() + " +0000");
            }

            if (!finalAnswerObject.endTime && !isLateAbsentNote) {
                finalAnswerObject.endTime = new Date(dateOfService?.toLocaleString() + " +0000");
            }

            if (edpnContext.selectedServicePlace) {
                finalAnswerObject.placeOfServiceId = 1;
            }

            if (edpnContext && edpnContext?.selectedCenter) {
                finalAnswerObject.centerId = edpnContext?.selectedCenter.centerId;
            }

            if (selectedLearner) {
                finalAnswerObject.clientId = Number(selectedLearner.value);
            }

            //this doesn't work because one is not selected
            /*if (edpnContext.selectedClient && edpnContext.selectedClient.podId) {
                finalAnswerObject.podId = edpnContext.selectedClient.podId;
            }*/


            finalAnswerObject.podId = selectedLearnerPodId;


            // swap with late reason id

            if (isLateAbsentNote && selectedAbsentReason) {
                //set absent stuff
                finalAnswerObject.absent = true;
                finalAnswerObject.absentReasonId = selectedAbsentReason.id;

                //set the answer to null just in case this is how the submit button is showing in the view page
                finalAnswerObject.answers = [];
            }
            if (selectedReason) {
                finalAnswerObject.lateReasonId = Number(selectedReason.value);
            }

            if (isLateAbsentNote) {
                finalAnswerObject.dpnstatusId = 4;
            }
            else {
                finalAnswerObject.dpnstatusId = 2;
            }

        }

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(finalAnswerObject),
            };

            const size = new TextEncoder().encode(JSON.stringify(finalAnswerObject)).length;
            const kiloBytes = size / 1024;

            console.log("finalanswerobject sent to api:", finalAnswerObject);

            var url = apiConfig.apiEndpoint + "/EDPN/UpdateEDPN";

            // Timeout setup
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 20000); // 20 seconds

            fetch(url, { ...options, signal: controller.signal })
                .then((res) => res.json())
                .then(
                    (result: APIReturnObject) => {
                        clearTimeout(timeoutId);
                        console.log("api result:", result);
                        if (result.status === Status.SUCCESS) {
                            if (result.message) {
                                const newLogObject = JSON.parse(result.message);
                                console.log("Autosaved:", newLogObject);

                                globalContext.setToastMessage((isLateAbsentNote ? "Absent " : "Late ") + "Note Added");
                                globalContext.setToastIcon("Success");
                                globalContext.setToastActive(true);

                                const oldLogObject = answerObject;

                                if (oldLogObject && newLogObject && newLogObject.LogId) {
                                    oldLogObject.logId = newLogObject.LogId;
                                }

                                setAnswerObject(oldLogObject);
                                setConfirmButtonActive(true);
                            }
                        }
                        else if (result.status === Status.FAIL) {
                            globalContext.setNotificationModalMessage("Cannot create " + (isLateAbsentNote ? "Absent " : "Late ") + "note. " + result.message);
                            globalContext.setNotificationModalActive(true);
                            setConfirmButtonActive(true);
                        }
                        else if (result.status === Status.ERROR) {
                            globalContext.setErrorModalProps({ errorMessage: "Failed to create Late note. " + result.message, errorLocation: "/EDPN/UpdateEDPN" });
                            globalContext.setErrorModalActive(true);
                            setConfirmButtonActive(true);
                        }
                        setIsSaving(false);
                        CancelModal();
                    },
                    (error) => {
                        clearTimeout(timeoutId);
                        if (error.name === "AbortError") {
                            console.log("Request timed out");
                            globalContext.setToastMessage("Save Failed. Connection timed out.");
                            globalContext.setToastIcon("Warning");
                            globalContext.setToastActive(true);
                        }
                        else {
                            globalContext.setErrorModalProps({
                                errorMessage: error,
                                errorLocation: "/EDPN/UpdateEDPN",
                            });
                            globalContext.setErrorModalActive(true);
                        }
                        setIsSaving(false);
                        CancelModal();
                    }
                );
        });
    }

    function CancelModal() {
        ClearValues();
        props.setActive(false);
    }

    function ConfirmModal() {
        if (props.isEDPN) {
            AddEDPNNote();
        } else {
            AddEclLog();
        }
    }

    function ClearValues() {
        setSelectedClinician(null);
        setSelectedCptCode(null);
        setSelectedLearner(null);
        setSelectedReason(null);
        setIsLateAbsentNote(false);
        setSelectedAbsentReason(null);
    }

    // Function to determine if a date should be disabled
    const normalizeDate = (date: any) => {
        const normalized = new Date(date);
        normalized.setHours(0, 0, 0, 0);
        return normalized;
    };

    const shouldDisableDate = (date: any) => {
        //const today = normalizeDate(new Date(2025, 0, 12)); // Example: Jan 5, 2025 (Sunday)
        const today = normalizeDate(new Date());
        const normalizedDate = normalizeDate(date);

        //const thisIsFalse = false; // this is because ecl does not work for me right now and I have to test 

        // Function to calculate the previous valid day, skipping weekends
        const getPreviousValidDay = (baseDate: Date, daysAgo: number): Date => {
            const validDate = new Date(baseDate);
            let daysCount = 0;

            while (daysCount < daysAgo) {
                validDate.setDate(validDate.getDate() - 1);
                const dayOfWeek = validDate.getDay();
                // Skip Saturday (6) and Sunday (0) if props.isEDPN is true
                if ((dayOfWeek !== 0 && dayOfWeek !== 6)) {
                    daysCount++;
                }
            }
            return normalizeDate(validDate);
        };

        const dayOfWeek = today.getDay();

        let todayIncluded, previousDay1, previousDay2, previousDay3, previousDay4, previousDay5;

        if (dayOfWeek === 6 || dayOfWeek === 0) {
            if (props.isEDPN) {
                // If today is Saturday or Sunday, adjust to Friday as "today"
                const adjustedToday = getPreviousValidDay(today, 1); // Set to Friday
                todayIncluded = adjustedToday; // Friday
                previousDay1 = getPreviousValidDay(adjustedToday, 1); // Thursday
                previousDay2 = getPreviousValidDay(adjustedToday, 2); // Wednesday
                previousDay3 = getPreviousValidDay(adjustedToday, 3); // Tuesday

                minimumDate = previousDay3;

                return !(
                    normalizedDate.getTime() === todayIncluded.getTime() ||
                    normalizedDate.getTime() === previousDay1.getTime() ||
                    normalizedDate.getTime() === previousDay2.getTime()
                );
            }
            else {
                //do not adjust todays date, just check if it is sat or sunday and go from there
                if (dayOfWeek === 6) {
                    // Not normal case.
                    todayIncluded = today;
                    previousDay1 = getPreviousValidDay(today, 1);
                    previousDay2 = getPreviousValidDay(today, 2);
                    previousDay3 = getPreviousValidDay(today, 3);

                    minimumDate = previousDay3;
                    return !(
                        normalizedDate.getTime() === todayIncluded.getTime() ||
                        normalizedDate.getTime() === previousDay1.getTime() ||
                        normalizedDate.getTime() === previousDay2.getTime() ||
                        normalizedDate.getTime() === previousDay3.getTime() 
                    );
                }
                else {
                    // Not normal case. SUNNNNDAAAAYYYYY
                    todayIncluded = today;
                    previousDay1 = getPreviousValidDay(today, 1);
                    previousDay2 = getPreviousValidDay(today, 2);
                    previousDay3 = getPreviousValidDay(today, 3);

                    const sunday = normalizeDate(new Date(today));
                    sunday.setDate(today.getDate() - (today.getDay() - 0));

                    // have to do saturday after sunday because sunday is part of this week and sat is not.
                    const saturday = normalizeDate(new Date(today));
                    saturday.setDate(sunday.getDate() - 1);

                    minimumDate = previousDay3;
                    return !(
                        normalizedDate.getTime() === todayIncluded.getTime() ||
                        normalizedDate.getTime() === saturday.getTime() ||
                        normalizedDate.getTime() === previousDay1.getTime() ||
                        normalizedDate.getTime() === previousDay2.getTime() ||
                        normalizedDate.getTime() === previousDay3.getTime() 
                    );
                }
            }
        } else {
            if (props.isEDPN) {
                // Normal case: calculate the previous three valid weekdays
                todayIncluded = today;
                previousDay1 = getPreviousValidDay(today, 1);
                previousDay2 = getPreviousValidDay(today, 2);
                previousDay3 = getPreviousValidDay(today, 3);

                minimumDate = previousDay3;

                return !(
                    normalizedDate.getTime() === todayIncluded.getTime() ||
                    normalizedDate.getTime() === previousDay1.getTime() ||
                    normalizedDate.getTime() === previousDay2.getTime() ||
                    normalizedDate.getTime() === previousDay3.getTime()
                );
            }
            else {
                // Normal case: calculate the previous three valid weekdays
                todayIncluded = today;
                previousDay1 = getPreviousValidDay(today, 1);
                previousDay2 = getPreviousValidDay(today, 2);
                previousDay3 = getPreviousValidDay(today, 3);
                
                const sunday = normalizeDate(new Date(today));
                sunday.setDate(today.getDate() - (today.getDay() - 0));

                // have to do saturday after sunday because sunday is part of this week and sat is not.
                const saturday = normalizeDate(new Date(today));
                saturday.setDate(sunday.getDate() - 1);

                if (today.getDay() === 5) {
                    minimumDate = previousDay3;

                    return !(
                        normalizedDate.getTime() === todayIncluded.getTime() ||
                        normalizedDate.getTime() === previousDay1.getTime() ||
                        normalizedDate.getTime() === previousDay2.getTime() ||
                        normalizedDate.getTime() === previousDay3.getTime() 
                    );
                }
                else {
                    if (saturday < previousDay3) {
                        minimumDate = saturday;
                    }
                    else {
                        minimumDate = previousDay3;
                    }

                    return !(
                        normalizedDate.getTime() === todayIncluded.getTime() ||
                        normalizedDate.getTime() === previousDay1.getTime() ||
                        normalizedDate.getTime() === previousDay2.getTime() ||
                        normalizedDate.getTime() === previousDay3.getTime() ||
                        normalizedDate.getTime() === saturday.getTime() ||
                        normalizedDate.getTime() === sunday.getTime()
                    );
                }
            }
            
        }
    };

    return (
        <div className={"z-[50] fixed inset-0 " + (props.isActive ? "normal" : "hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[40]"></div>

            <div className="fixed inset-0 z-[50] overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all z-[60]">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="">
                                <div className="mt-3 sm:mt-0 p-10">

                                    <div className="place-items-center">
                                        <h2 className="text-2xl text-lacblue font-bold">Add Late { isLateAbsentNote ? "Absent" : ""} {props.isEDPN ? "eDPN" : "eClinical"} Note</h2>
                                        <h3 className="italic text-blue mb-5">(After Date of Service)</h3>
                                    </div>
                                    

                                    <div className="w-full">
                                        <label className="text-lg leading-6 text-left block" id="modal-title">
                                            <span className="font-medium">Date of Service</span>
                                            <span className="italic ">(Must be within 72 hours of today)</span>
                                        </label>
                                        <DatePicker
                                            value={dateOfService}
                                            shouldDisableDate={shouldDisableDate}
                                            disabled={false}
                                            disableFuture={false}
                                            onChange={(newValue) => setDateOfService(newValue)}
                                            className="w-full"
                                            renderInput={(params) => <TextField size="small" className="w-full" {...params} />}
                                        />
                                    </div>

                                    <div>
                                        <label className="leading-6 block text-left" id="modal-title">
                                            <span className="font-medium text-lg">Rendering Clinician</span>
                                            <span className="italic text-md">(Who provided the service to the learner {props.isEDPN ? "" : ", Cannot select yourself" })</span>
                                        </label>
                                        <Select
                                            className="w-full"
                                            options={filteredClinicians && filteredClinicians.length > 0 ? filteredClinicians.map((clinician) => ({ value: String(clinician.therapistId), label: clinician.employeeName })) : undefined}
                                            onChange={OnClinicianChange}
                                            value={selectedClinician}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label className="text-lg leading-6 font-medium text-left">CPT Code</label>
                                        <Select
                                            className="w-full"
                                            options={FilteredCPTCodes && FilteredCPTCodes.length > 0 ? FilteredCPTCodes.map((cptCode) => ({ value: String(cptCode.value), label: cptCode.label })) : undefined}
                                            onChange={OnCptCodeChange}
                                            value={selectedCptCode}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label className="text-lg leading-6 font-medium text-left">Learner Name</label>
                                        <Select
                                            className="w-full"
                                            options={clients && clients.length > 0 ? clients.map((client) => ({ value: String(client.clientId), label: String(client.fullName) })) : undefined}
                                            onChange={OnSelectedClientChange}
                                            value={selectedLearner}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                            }}
                                        />
                                    </div>

                                    <div className={props.isEDPN ? "" : "hidden"}>
                                        <label className="text-lg block font-bold" htmlFor="centers">
                                            Absent Note:
                                        </label>

                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                onChange={e => setIsLateAbsentNote(e.target.checked)}
                                                checked={isLateAbsentNote}
                                                className="sr-only peer"
                                            />
                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                                        </label>
                                    </div>

                                    <div className={isLateAbsentNote ? "" : "hidden"}>
                                        <label className="text-lg block font-bold" htmlFor="centers">
                                            Absent Reason:
                                        </label>
                                        <Select
                                            className="w-full"
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                            }}
                                            isSearchable={false}
                                            options={edpnContext.absentReasons ? edpnContext.absentReasons?.filter(x => x.absent == true) : undefined}
                                            onChange={setSelectedAbsentReason}
                                            value={selectedAbsentReason}
                                        />
                                    </div>
                                    

                                    <div>
                                        <label className="text-lg leading-6 text-left">
                                            <span className="font-medium">Reason</span>
                                            <span className="italic">(Reason for note submission after date of service)</span>
                                        </label>
                                        <Select
                                            className="w-full"
                                            options={lateReasons && lateReasons.length > 0 ? lateReasons.map((reason) => ({ value: String(reason.reasonId), label: String(reason.reason) })) : undefined}
                                            onChange={OnSelectedReasonChange}
                                            value={selectedReason}
                                            menuPortalTarget={document.body}
                                            menuPosition="fixed"
                                            styles={{
                                                menuPortal: (base) => ({ ...base, zIndex: 1000 })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex bg-gray-50 px-4 py-3 sm:px-6 mx-auto">
                            <div className="flex justify-center mx-auto">
                                <button onClick={CancelModal} className="bg-rose w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600">
                                    <span className="drop-shadow">Cancel</span>
                                </button>
                                <button onClick={ConfirmModal} disabled={!confirmButtonActive} className={"bg-lacgreen ml-5 w-[7rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 " + (confirmButtonActive ? 'hover:bg-lacgreen-600' : 'opacity-50 cursor-not-allowed')}>
                                    {isSaving ? <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin m-auto" icon={solid('spinner')} /> : <span className="drop-shadow">Confirm</span> }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}