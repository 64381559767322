// Msal imports
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { apiConfig, loginRequest } from "../../authConfig";

// imports
import { Loading } from "../../components/Loading";
import { ErrorComponent } from "../../components/ErrorComponent";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../utils/GlobalContext";
import { CredentialsContext } from "./CredentialsContext";

import ProtectedComponent from "../../utils/ProtectedComponent";
import ProtectedPage from "../../utils/ProtectedPage";
import { ProfilePicture } from "../../components/ProfilePicture";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APIReturnObject, ChoiceOption, Client, ClientContact, LearnerInfo, NewClient, Status, VwAuthorization, VwAuthorizationDetail, VwEzclaimInsuranceAuthorizationsMatched, PayorInfoType, AuthorizationStatus, Schedule } from "../../utils/Definitions";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ProfilePictureModal } from "../../components/ProfilePictureModal";
import Select from "react-select";
import ValueType from "react-select";
import OptionType from "react-select";

import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';

import PropTypes from 'prop-types'
import { InputMask, type InputMaskProps, type MaskOptions } from '@react-input/mask';

// Sample app imports
import { FC, SetStateAction, useCallback, useMemo } from "react";
import { Center, ScheduleEvent } from "../../utils/Definitions";
import { SingleValue } from "react-select";
import { ModalDailyScheduleEvent } from "../../components/ModalDailyScheduleEvent";
import { ModalNotification } from "../../components/ModalNotification";

import { Calendar, dateFnsLocalizer, Views, Event, View, Week, stringOrDate } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import addDays from "date-fns/addDays";
import { ProfilePictureSmall } from "../../components/ProfilePictureSmall";
import { ValueContainer } from "../../components/ValueContainer";
import { AuthorizationModal } from "../../components/authorizations/AuthorizationModal";

// This is the calendar item
const resourceMap = [
    { resourceId: 2, resourceTitle: 'Monday' },
    { resourceId: 3, resourceTitle: 'Tuesday' },
    { resourceId: 4, resourceTitle: 'Wednesday' },
    { resourceId: 5, resourceTitle: 'Thursday' },
    { resourceId: 6, resourceTitle: 'Friday' },
]

const DeductibleTypes = [
    { value: 'individual', label: 'Individual' },
    { value: 'family', label: 'Family' },
]

const OutOfPocketTypes = [
    { value: 'individual', label: 'Individual' },
    { value: 'family', label: 'Family' },
]


//only renders if authenticated
const LearnerEditContent = () => {

    const globalContext = useContext(GlobalContext);
    const credentialsContext = useContext(CredentialsContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);

    const [isAdding, setIsAdding] = useState<boolean>(false);


    const [selectedTab, setSelectedTab] = useState<"profile" | "payor" | "secondarypayor" | "tertiarypayor" | "schedule" | "authorization">("profile");
    const [authorizationContent, setAuthorizationContent] = useState<"main" | "import" | "denial">("main");


    const [selectedClient, setSelectedClient] = useState<LearnerInfo | null>(null);
    const [clientData, setClientData] = useState<LearnerInfo | null>(null);

    const [selectedClientForModal, setSelectedClientForModal] = useState<Client | null>(null);
    const [profilePictureModalActive, setProfilePictureModalActive] = useState<boolean>(false);

    const [clients, setClients] = useState<ChoiceOption[]>([]);
    const [selectedClientChoice, setSelectedClientChoice] = useState<ChoiceOption | null>(null);

    const [draggedEvent, setDraggedEvent] = useState();
    const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);

    const [events, setEvents] = useState<ScheduleEvent[]>([]);

    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

    const [isEventModalActive, setIsEventModalActive] = useState<boolean>(false);

    const [overlapModalActive, setOverlapModalActive] = useState<boolean>(false);
    const [overlapMessage, setOverlapMessage] = useState<string>("");

    const [successModalActive, setSuccessModalActive] = useState<boolean>(false);

    const [showInactiveLearners, setShowInactiveLearners] = useState<boolean>(false);

    const [centerValue, setCenterValue] = useState<ChoiceOption | null>(null);
    const [podValue, setPodValue] = useState<ChoiceOption | null>(null);
    const [primaryRelationshipValue, setPrimaryRelationshipValue] = useState<ChoiceOption | null>(null);
    const [primaryPayorInsuranceValue, setPrimaryPayorInsuranceValue] = useState<ChoiceOption | null>(null);
    const [primaryFundingTypeValue, setPrimaryFundingTypeValue] = useState<ChoiceOption | null>(null);
    const [secondaryPayorInsuranceValue, setSecondaryPayorInsuranceValue] = useState<ChoiceOption | null>(null);
    const [secondaryFundingTypeValue, setSecondaryFundingTypeValue] = useState<ChoiceOption | null>(null);
    const [tertiaryPayorInsuranceValue, setTertiaryPayorInsuranceValue] = useState<ChoiceOption | null>(null);
    const [tertiaryFundingTypeValue, setTertiaryFundingTypeValue] = useState<ChoiceOption | null>(null);
    const [transitionReasonValue, setTransitionReasonValue] = useState<ChoiceOption | null>(null);
    const [dischargeReasonValue, setDischargeReasonValue] = useState<ChoiceOption | null>(null);
    const [primaryDeductibleValue, setPrimaryDeductibleValue] = useState<ChoiceOption | null>(null);
    const [primaryOutOfPocketTypeValue, setPrimaryOutOfPocketTypeValue] = useState<ChoiceOption | null>(null);
    const [secondaryDeductibleValue, setSecondaryDeductibleValue] = useState<ChoiceOption | null>(null);
    const [secondaryOutOfPocketTypeValue, setSecondaryOutOfPocketTypeValue] = useState<ChoiceOption | null>(null);
    const [tertiaryDeductibleValue, setTertiaryDeductibleValue] = useState<ChoiceOption | null>(null);
    const [tertiaryOutOfPocketTypeValue, setTertiaryOutOfPocketTypeValue] = useState<ChoiceOption | null>(null);

    const [payorInfoTypes, setPayorInfoTypes] = useState<PayorInfoType[]>([]);

    const [importButtonActive, setImportButtonActive] = useState<boolean>(false);
    const [clientAuthorizations, setClientAuthorizations] = useState<VwAuthorizationDetail[]>([]);
    const [ezClaimAuthorizations, setEzClaimAuthorizations] = useState<VwEzclaimInsuranceAuthorizationsMatched[]>([]);
    const [authorizationStatuses, setAuthorizationStatuses] = useState<AuthorizationStatus[]>([]);

    const [reportDueDate, setReportDueDate] = useState<Date | null>(new Date());
    const [isBucket, setIsBucket] = useState<ChoiceOption | null>();
    const [selectedPayorType, setSelectedPayorType] = useState<ChoiceOption | null>(null);
    const [selectedAuthStatus, setSelectedAuthStatus] = useState<ChoiceOption | null>(null);

    const [selectedClientId, setSelectedClientId] = useState<number | undefined>(undefined);
    const [selectedAuthId, setSelectedAuthId] = useState<string | null>(null);
    const [isAuthorizationModalActive, setIsAuthorizationModalActive] = useState<boolean>(false);

    const [hasTertiary, setHasTertiary] = useState<boolean>(false);
    const [hasSecondary, setHasSecondary] = useState<boolean>(false);

    const [topScheduleChoices, setTopScheduleChoices] = useState<ChoiceOption[] | null>();

    let tScheduleId: number = 0;

    useEffect(() => {

        const hasTertiaryData = clientData && (
            clientData.tertiaryPayorSubscriberID?.trim() ||
            clientData.tertiarySubscriberFirstName?.trim() ||
            clientData.tertiarySubscriberMiddleName?.trim() ||
            clientData.tertiarySubscriberLastName?.trim() ||
            clientData.tertiarySubscriberDOB !== null ||
            clientData.tertiarySubscriberAddress?.trim() ||
            clientData.tertiarySubscriberCity?.trim() ||
            clientData.tertiarySubscriberState?.trim() ||
            clientData.tertiarySubscriberZipCode?.trim() ||
            clientData.tertiaryPayorInsuranceID !== 0 ||
            clientData.tertiaryPayorFundingTypeID !== 0 ||
            clientData.tertiaryDeductibleType?.trim() ||
            clientData.tertiaryOutOfPocketType?.trim() ||
            clientData.tertiaryCopayFrequency?.trim()
        );

        setHasTertiary(!!hasTertiaryData);

        const hasSecondaryData = clientData && (
            clientData.secondaryPayorSubscriberID?.trim() ||
            clientData.secondarySubscriberFirstName?.trim() ||
            clientData.secondarySubscriberMiddleName?.trim() ||
            clientData.secondarySubscriberLastName?.trim() ||
            clientData.secondarySubscriberDOB !== null ||
            clientData.secondarySubscriberAddress?.trim() ||
            clientData.secondarySubscriberCity?.trim() ||
            clientData.secondarySubscriberState?.trim() ||
            clientData.secondarySubscriberZipCode?.trim() ||
            clientData.secondaryPayorInsuranceID !== 0 ||
            clientData.secondaryPayorFundingTypeID !== 0 ||
            clientData.secondaryDeductibleType?.trim() ||
            clientData.secondaryOutOfPocketType?.trim() ||
            clientData.secondaryCopayFrequency?.trim()
        );

        setHasSecondary(!!hasSecondaryData);

    }, [clientData]);


    useEffect(() => {
        if (selectedTab !== "authorization") {
            setAuthorizationContent("main");
        }
    }, [selectedTab]);

    useEffect(() => {
        handleSelectChange(centerValue, 'centerID');
    }, [centerValue]);

    useEffect(() => {
        handleSelectChange(podValue, 'podID');
    }, [podValue]);

    useEffect(() => {
        handleSelectChange(primaryRelationshipValue, 'primaryContactRelationshipID');
    }, [primaryRelationshipValue]);

    useEffect(() => {
        handleSelectChange(primaryPayorInsuranceValue, 'primaryPayorInsuranceID');
    }, [primaryPayorInsuranceValue]);

    useEffect(() => {
        handleSelectChange(primaryFundingTypeValue, 'primaryPayorFundingTypeID');
    }, [primaryFundingTypeValue]);

    useEffect(() => {
        handleSelectChange(secondaryPayorInsuranceValue, 'secondaryPayorInsuranceID');
    }, [secondaryPayorInsuranceValue]);

    useEffect(() => {
        handleSelectChange(secondaryFundingTypeValue, 'secondaryPayorFundingTypeID');
    }, [secondaryFundingTypeValue]);

    useEffect(() => {
        handleSelectChange(tertiaryPayorInsuranceValue, 'tertiaryPayorInsuranceID');
    }, [tertiaryPayorInsuranceValue]);

    useEffect(() => {
        handleSelectChange(tertiaryFundingTypeValue, 'tertiaryPayorFundingTypeID');
    }, [tertiaryFundingTypeValue]);

    useEffect(() => {
        handleSelectChange(transitionReasonValue, 'lastDayOf97153ReasonID');
    }, [transitionReasonValue]);

    useEffect(() => {
        handleSelectChange(dischargeReasonValue, 'lastDayOfServiceReasonID');
    }, [dischargeReasonValue]);

    useEffect(() => {
        handleSelectChange(primaryDeductibleValue, 'primaryDeductibleType');
    }, [primaryDeductibleValue]);

    useEffect(() => {
        handleSelectChange(primaryOutOfPocketTypeValue, 'primaryOutOfPocketType');
    }, [primaryOutOfPocketTypeValue]);

    useEffect(() => {
        handleSelectChange(secondaryDeductibleValue, 'secondaryDeductibleType');
    }, [secondaryDeductibleValue]);

    useEffect(() => {
        handleSelectChange(secondaryOutOfPocketTypeValue, 'secondaryOutOfPocketType');
    }, [secondaryOutOfPocketTypeValue]);

    useEffect(() => {
        handleSelectChange(tertiaryDeductibleValue, 'tertiaryDeductibleType');
    }, [tertiaryDeductibleValue]);

    useEffect(() => {
        handleSelectChange(tertiaryOutOfPocketTypeValue, 'tertiaryOutOfPocketType');
    }, [tertiaryOutOfPocketTypeValue]);

    useEffect(() => {
        console.log("selectedClient:", selectedClient);
        if (selectedClient) {
            GetScheduleForClient();

            if (globalContext.CheckRoles(["Authorizations.InsuranceAccess", "Authorizations.AppAccess", "ADMIN"])) {
                GetAuthorizationsForClient();
                GetAuthorizationsFromEzClaim();
                GetPayorInfoTypes();
                GetAuthorizationStatuses();
                setSelectedClientId(selectedClient.clientID);
            }
        }

    }, [selectedClient]);


    useEffect(() => {
        console.log("selectedClient:", selectedClient);
        if (selectedClientId) {
            GetClientById();
        }

    }, [selectedClientId]);

    useEffect(() => {
        console.log("clientData:", clientData);
    }, [clientData]);

    useEffect(() => {
        credentialsContext.UpdateStuff();
        GetTopSchedules();
    }, []);

    useEffect(() => {
        GetAllClientsList(showInactiveLearners);

    }, [showInactiveLearners]);

    useEffect(() => {
        console.log("isAdding:", isAdding);

        if (isAdding) {
            // Create new client here
            const newClient = createBlankClient();
            setSelectedTab("profile");
            setSelectedClient(newClient);
            setCenterValue(null);
        } else {
            setSelectedClient(null); // Reset selectedClient when not adding
        }

    }, [isAdding]);

    // Function to create a new blank client
    const createBlankClient = (): LearnerInfo => ({
        centerName: '',
        learnerFirstName: '',
        learnerLastName: '',
        learnerMiddleName: '',
        learnerDOB: null,
        learnerAddress: '',
        learnerAddress2: '',
        learnerCity: '',
        learnerState: '',
        learnerZipCode: '',
        primaryContactFirstName: '',
        primaryContactLastName: '',
        primaryContactAddress: '',
        primaryContactAddress2: '',
        primaryContactCity: '',
        primaryContactState: '',
        primaryContactZipCode: '',
        primaryContactPhoneNumber: '',
        primaryContactEmail: '',
        primaryContactRelationshipID: undefined,
        therapyScheduleID: 0,
        primarySubscriberFirstName: '',
        primarySubscriberLastName: '',
        primarySubscriberMiddleName: '',
        primarySubscriberAddress: '',
        primarySubscriberAddress2: '',
        primarySubscriberCity: '',
        primarySubscriberState: '',
        primarySubscriberZipCode: '',
        primarySubscriberDOB: null,
        primaryPayorInsuranceID: 0,
        primaryPayorSubscriberID: '',
        primaryPayorFundingTypeID: 0,
        primaryCopay: undefined,
        primaryCoinsurance: '',
        primaryDeductible: undefined,
        primaryOutOfPocketMax: undefined,
        primaryEffectiveDate: null,
        primaryBenefitPeriodStart: null,
        primaryBenefitPeriodEnd: null,
        primaryDeductibleType: '',
        primaryCopayFrequency: '',
        primaryOutOfPocketType: '',
        secondarySubscriberFirstName: '',
        secondarySubscriberLastName: '',
        secondarySubscriberMiddleName: '',
        secondarySubscriberAddress: '',
        secondarySubscriberAddress2: '',
        secondarySubscriberCity: '',
        secondarySubscriberState: '',
        secondarySubscriberZipCode: '',
        secondarySubscriberDOB: null,
        secondaryPayorInsuranceID: 0,
        secondaryPayorSubscriberID: '',
        secondaryPayorFundingTypeID: 0,
        secondaryCopay: undefined,
        secondaryCoinsurance: '',
        secondaryDeductible: undefined,
        secondaryOutOfPocketMax: undefined,
        secondaryEffectiveDate: null,
        secondaryBenefitPeriodStart: null,
        secondaryBenefitPeriodEnd: null,
        secondaryDeductibleType: '',
        secondaryCopayFrequency: '',
        secondaryOutOfPocketType: '',
        tertiarySubscriberFirstName: '',
        tertiarySubscriberLastName: '',
        tertiarySubscriberMiddleName: '',
        tertiarySubscriberAddress: '',
        tertiarySubscriberAddress2: '',
        tertiarySubscriberCity: '',
        tertiarySubscriberState: '',
        tertiarySubscriberZipCode: '',
        tertiarySubscriberDOB: null,
        tertiaryPayorInsuranceID: 0,
        tertiaryPayorSubscriberID: '',
        tertiaryPayorFundingTypeID: 0,
        tertiaryCopay: undefined,
        tertiaryCoinsurance: '',
        tertiaryDeductible: undefined,
        tertiaryOutOfPocketMax: undefined,
        tertiaryEffectiveDate: null,
        tertiaryBenefitPeriodStart: null,
        tertiaryBenefitPeriodEnd: null,
        tertiaryDeductibleType: '',
        tertiaryCopayFrequency: '',
        tertiaryOutOfPocketType: '',
        learnerStatus: true,
        learnerStartDate: null,
        centerID: undefined,
        podID: 0,
        rbtRequired: false,
        telehealthAvailable: false,
        lastDayOf97153: null,
        lastDayOf97153ReasonID: undefined,
        lastDayOfService: null,
        lastDayOfServiceReasonID: undefined,
    });

    function CopyLearnerToPrimary() {
        if (clientData) {
            setClientData({
                ...clientData,
                primarySubscriberAddress: clientData.learnerAddress,
                primarySubscriberAddress2: clientData.learnerAddress2,
                primarySubscriberCity: clientData.learnerCity,
                primarySubscriberState: clientData.learnerState,
                primarySubscriberZipCode: clientData.learnerZipCode,
            });
        }
    }

    function CopyContactToPrimary() {
        if (clientData) {
            setClientData({
                ...clientData,
                primarySubscriberAddress: clientData.primaryContactAddress,
                primarySubscriberAddress2: clientData.primaryContactAddress2,
                primarySubscriberCity: clientData.primaryContactCity,
                primarySubscriberState: clientData.primaryContactState,
                primarySubscriberZipCode: clientData.primaryContactZipCode,
            });
        }
    }

    function CopyLearnerToSecondary() {
        if (clientData) {
            setClientData({
                ...clientData,
                secondarySubscriberAddress: clientData.learnerAddress,
                secondarySubscriberAddress2: clientData.learnerAddress2,
                secondarySubscriberCity: clientData.learnerCity,
                secondarySubscriberState: clientData.learnerState,
                secondarySubscriberZipCode: clientData.learnerZipCode,
            });
        }
    }

    function CopyContactToSecondary() {
        if (clientData) {
            setClientData({
                ...clientData,
                secondarySubscriberAddress: clientData.primaryContactAddress,
                secondarySubscriberAddress2: clientData.primaryContactAddress2,
                secondarySubscriberCity: clientData.primaryContactCity,
                secondarySubscriberState: clientData.primaryContactState,
                secondarySubscriberZipCode: clientData.primaryContactZipCode,
            });
        }
    }

    function CopyLearnerToTertiary() {
        if (clientData) {
            setClientData({
                ...clientData,
                tertiarySubscriberAddress: clientData.learnerAddress,
                tertiarySubscriberAddress2: clientData.learnerAddress2,
                tertiarySubscriberCity: clientData.learnerCity,
                tertiarySubscriberState: clientData.learnerState,
                tertiarySubscriberZipCode: clientData.learnerZipCode,
            });
        }
    }

    function CopyContactToTertiary() {
        if (clientData) {
            setClientData({
                ...clientData,
                tertiarySubscriberAddress: clientData.primaryContactAddress,
                tertiarySubscriberAddress2: clientData.primaryContactAddress2,
                tertiarySubscriberCity: clientData.primaryContactCity,
                tertiarySubscriberState: clientData.primaryContactState,
                tertiarySubscriberZipCode: clientData.primaryContactZipCode,
            });
        }
    }

    function CopyLearnerToContact() {
        if (clientData) {
            setClientData({
                ...clientData,
                primaryContactAddress: clientData.learnerAddress,
                primaryContactAddress2: clientData.learnerAddress2,
                primaryContactCity: clientData.learnerCity,
                primaryContactState: clientData.learnerState,
                primaryContactZipCode: clientData.learnerZipCode,
            });
        }
    }

    function SetClientSchedule(scheduleId: number) {
        if (clientData) {
            tScheduleId = scheduleId;
        }
    }

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | any,
        dateValue?: Date | null
    ) => {
        if (e && e.target) {
            const { name, type, checked, value } = e.target;

            if (clientData) {
                setClientData({
                    ...clientData,
                    [name]: type === 'checkbox' ? checked : value,
                });
            }
        } else if (dateValue !== undefined) {
            const { name } = e; // name will be passed as part of the event
            if (clientData) {
                setClientData({
                    ...clientData,
                    [name]: dateValue,
                });
            }
        }
    };

    function handleSelectChange(selectedOption: SingleValue<ChoiceOption>, name: string) {

        if (selectedOption) {
            const selectedValue = Array.isArray(selectedOption) // Check if it's a multi-select
                ? selectedOption.map(option => option.value)
                : selectedOption.value;

            if (clientData) {
                setClientData({
                    ...clientData,
                    [name]: selectedValue,
                });
            }
        } else {
            // Handle case where selectedOption is null or undefined
            if (clientData) {
                setClientData({
                    ...clientData,
                    [name]: null, // Or handle this based on your use case
                });
            }
        }

    }

    function handleRadioChange(event: React.MouseEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget; // Use currentTarget instead of target

        if (clientData) {
            // Get the current value using optional chaining in case the property doesn't exist
            const currentValue = clientData?.[name as keyof typeof clientData];

            // Toggle logic: If the clicked value is the same as the current value, unselect it by setting it to null
            const newValue = currentValue === value ? null : value;
            console.log("asdf:", value, currentValue);

            setClientData({
                ...clientData,
                [name]: newValue,
            });
        }
    }

    function GetTopSchedules() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();

            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);

            var options = {
                method: "GET",
                headers: headers,
            };

            fetch(apiConfig.apiEndpoint + "/credentials/GetTopSchedules", options)
                .then(res => res.json())
                .then(
                    (result) => {

                        const mappedSchedules: ChoiceOption[] = result.map((schedule: any) => ({
                            value: schedule.therapyScheduleId.toString(),  // Assuming therapyScheduleId is a number, convert to string if needed
                            label: schedule.description
                        }));


                        setTopScheduleChoices(mappedSchedules);
                        console.log("top schedules:", mappedSchedules);
                    },
                    (error) => {
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/credentials/GetTopSchedules" });
                        globalContext.setErrorModalActive(true);
                    }
            )
        });
    }



    function GetScheduleForClient() {
        if (selectedClient && selectedClient.clientID) {
            globalContext.GetToken().then((token: any) => {
                if (selectedClient) {

                    var headers = new Headers();

                    var bearer = "Bearer " + token;
                    headers.append("Authorization", bearer);

                    var options = {
                        method: "GET",
                        headers: headers,
                    };

                    console.log("booyah:", selectedClient?.clientID);

                    fetch(apiConfig.apiEndpoint + "/Billing/GetScheduleForClient?clientID=" + selectedClient?.clientID, options)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                console.log("list of events:", result);
                                var formattedListOfEvents = FormatEventsForCalendar(result);
                                setEvents(formattedListOfEvents);
                                //setEvents(result);
                            },
                            (error) => {
                                globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Billing/GetScheduleForClient" });
                                globalContext.setErrorModalActive(true);
                            }
                        )

                }


            });
        }
    }

    function FormatEventsForCalendar(events: any): any {

        // the start and end dates have to be todays date, otherwise it will not show up on the calendar
        const today = new Date();

        if (events) {
            return events.map((event: any) => {

                // Set the start time
                const thisStart = new Date(today);
                thisStart.setHours(event.startHour, event.startMinute, 0, 0);

                // Set the end time
                const thisEnd = new Date(today);
                thisEnd.setHours(event.endHour, event.endMinute, 0, 0);

                return {
                    id: event.id,
                    resourceId: event.resourceId,
                    start: thisStart,
                    end: thisEnd,
                };
            });
        }

    }




    function SaveScheduleForClient() {
        globalContext.GetToken().then((token: any) => {
            var hasOverlap = hasOverlappingEvents(events);

            if (selectedClient && !hasOverlap) {

                var formattedEvents = FormatEventsForSave(events);

                console.log("SCHEDULE FORMATTED EVENTS:", formattedEvents);

                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(formattedEvents)
                };

                setLoading(true);


                var url = apiConfig.apiEndpoint + "/Billing/SetScheduleForClient?clientTherapyScheduleId=" + selectedClient?.therapyScheduleID;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            console.log("SAVE SCHEUDLE status:", result);

                            if (result !== -1) {
                                // set the schedule id from this return
                                SetClientSchedule(result);

                                SaveLearner();
                            }
                            else {
                                globalContext.setNotificationModalMessage("Error saving Schedule.");
                                globalContext.setNotificationModalActive(true);
                                setLoading(false);

                            }

                        },
                        (error) => {
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Billing/SetScheduleForClient" });
                            globalContext.setErrorModalActive(true);
                            setLoading(false);

                        }
                    )
            }
            else {
                setOverlapMessage("Please make sure there are no overlapping schedules on the same day.");
                setOverlapModalActive(true);
            }
        })
    }

    function FormatEventsForSave(newEvents: any) {
        return newEvents.map((event: any) => {
            const start = new Date(event.start);
            const end = new Date(event.end);

            return {
                id: event.id,
                resourceId: event.resourceId,
                startHour: start.getHours(),
                startMinute: start.getMinutes(),
                endHour: end.getHours(),
                endMinute: end.getMinutes()
            };
        });
    }

    function hasOverlappingEvents(events: any): boolean {
        // Sort events by dayId and then by start time
        events.sort((a: any, b: any) => {
            if (a.resourceId !== b.resourceId) {
                return a.resourceId - b.resourceId;
            }
            return a.start.getTime() - b.start.getTime();
        });

        // Iterate through sorted events and check for overlaps
        for (let i = 0; i < events.length - 1; i++) {
            const currentEvent = events[i];
            const nextEvent = events[i + 1];

            // Check if the next event is on the same day and overlaps with the current event
            if (currentEvent.resourceId === nextEvent.resourceId && currentEvent.end > nextEvent.start) {
                return true; // Overlapping event found
            }
        }

        return false; // No overlapping events found
    }


    const customOnDragOver = useCallback(
        (dragEvent: any) => {
            // check for undroppable is specific to this example
            // and not part of API. This just demonstrates that
            // onDragOver can optionally be passed to conditionally
            // allow draggable items to be dropped on cal, based on
            // whether event.preventDefault is called
            if (draggedEvent !== 'undroppable') {
                dragEvent.preventDefault()
            }
        },
        [draggedEvent]
    )

    const moveEvent = useCallback(
        ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }:
            {
                event: any;
                start: stringOrDate;
                end: stringOrDate;
                isAllDay?: boolean | undefined;
            }
        ) => {
            const { allDay } = event
            if (!allDay && droppedOnAllDaySlot) {
                event.allDay = true
            }

            setEvents((prev: any) => {
                const existing = prev.find((ev: any) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev: any) => ev.id !== event.id)
                return [...filtered, { ...existing, start, end, allDay }]
            })
        },
        [setEvents]
    )

    const newEvent = useCallback(
        (event: any) => {
            //Stop from setting full day events
            const start = new Date(event.start);
            const end = new Date(event.end);

            if (start.getHours() == 0 && end.getHours() == 0) {
                return;
            }

            setEvents((prev) => {

                const newId = prev.length + 1;

                return [...prev, { ...event, id: newId }]
            })
        },
        [setEvents]
    )

    const resizeEvent = useCallback(
        ({ event, start, end }:
            {
                event: any;
                start: stringOrDate;
                end: stringOrDate;
            }) => {
            setEvents((prev: any) => {
                const existing = prev.find((ev: any) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev: any) => ev.id !== event.id)
                return [...filtered, { ...existing, start, end }]
            })
        },
        [setEvents]
    )

    const deleteEvent = (pEvent: any) => {
        // Filter out the event with the given id
        const updatedEvents = events.filter((event: any) => event.id !== pEvent.id);
        // Update the events array
        setEvents(updatedEvents);
    }

    const updateEvent = (eventId: number, newStart: Date, newEnd: Date) => {
        // Create a new list of events with the updated event
        const updatedEvents = events.map((event: any) =>
            event.id === eventId ? { ...event, start: newStart, end: newEnd } : event
        );

        // Update the state with the new list of events
        setEvents(updatedEvents);

    }

    const MyCalendar = () => {

        interface CustomHeaderProps {
            view: View;
            label: string;
        }

        const CustomHeader: React.FC<CustomHeaderProps> = ({ view, label }) => {
            // Your custom header component logic here
            return (<></>);
            return (
                <div>
                    <div className="grid justify-center">
                        <span className="font-bold text-xl">Weekly Schedule</span>
                    </div>
                </div>
            );
        };

        //Clicking an existing event allows you to remove it
        const onSelectEvent = (pEvent: any) => {

            setSelectedEvent(pEvent);
            setIsEventModalActive(true);

        }

        useEffect(() => {
            const timeHeaders = document.querySelectorAll('.rbc-time-gutter, .rbc-time-header-gutter');
            timeHeaders.forEach((header) => {
                const element = header as HTMLElement;
                element.style.zIndex = '0';
                element.style.position = 'relative';
            });



        }, []);

        // This was my attempt to rid us of the green line...this was unsuccessful. But with a little more time, this can be figured out.
        //hudson your attempt was trash and you should feel bad. took me (the greatest coder alive) 5 mins to fix this by adding 1 line to the index.css
        /*
        useEffect(() => {
            // remove the current time indicator
            const currentTimeIndicator = document.querySelectorAll('.rbc-current-time-indicator');
            currentTimeIndicator.forEach((line) => {
                const element = line as HTMLElement;
                element.style.display = 'none';
            });
        }, []);
        */

        if (selectedClient) {
            return (
                <div>
                    <DnDCalendar
                        defaultView='day'
                        events={events}
                        views={['day']}
                        localizer={localizer}
                        resizable
                        resourceIdAccessor={(event: any) => event.resourceId}
                        resources={resourceMap}
                        resourceTitleAccessor={(event: any) => event.resourceTitle}
                        components={{
                            toolbar: CustomHeader,
                        }}
                        selectable
                        onDragOver={customOnDragOver}
                        onEventDrop={moveEvent}
                        onEventResize={resizeEvent}
                        onSelectSlot={newEvent}
                        onSelectEvent={event => onSelectEvent(event)} //Fires selecting existing event
                        min={new Date(0, 0, 0, 7)} // Set minimum time to 7am
                        max={new Date(0, 0, 0, 19)} // Set maximum time to 6pm (18:00)
                        step={15} // Set step to 60 minutes (1 hour)
                        timeslots={4} // Number of timeslots per hour (12 timeslots = 5-minute intervals)
                        style={{ height: '61vh', }}
                    />
                </div>
            );
        }
        else {
            return (
                <></>
            );
        }
    }

    function NavigateBack() {
        navigate("/");
    }

    useEffect(() => {
        GetAllClientsList(false);
    }, []);

    //clear selected client if they dont exist after toggle switch
    useEffect(() => {
        if (clients) {
            var foundClient = clients.find(x => x.value == selectedClientChoice?.value);
            if (!foundClient) {
                setSelectedClientChoice(null);
            }
        }
        else {
            setSelectedClientChoice(null);
        }

    }, [clients]);

    useEffect(() => {
        if (selectedClientChoice) {
            SelectClientByID(Number(selectedClientChoice.value));
        }
        else {
            setSelectedClient(null);
        }
    }, [selectedClientChoice]);

    useEffect(() => {
        setClientData(selectedClient);

        //populate center dropdown
        if (selectedClient) {
            var center = globalContext?.centerChoices?.find(
                (option) => Number(option.value) === selectedClient?.centerID
            );
            center ? setCenterValue(center) : setCenterValue(null);
        }

        //populate pod dropdown
        if (selectedClient) {
            var pod = globalContext?.podChoices?.find(
                (option) => Number(option.value) === selectedClient?.podID
            );
            pod ? setPodValue(pod) : setPodValue(null);
        }

        //populate primary relationship dropdown
        if (selectedClient) {
            var primaryrelationship = credentialsContext?.clientRelationships?.find(
                (option) => Number(option.value) === selectedClient?.primaryContactRelationshipID
            );
            primaryrelationship ? setPrimaryRelationshipValue(primaryrelationship) : setPrimaryRelationshipValue(null);
        }

        //populate primary payor insurance dropdown
        if (selectedClient) {
            var primarypayorinsurance = credentialsContext?.companyInsurances?.find(
                (option) => Number(option.value) === selectedClient?.primaryPayorInsuranceID
            );
            primarypayorinsurance ? setPrimaryPayorInsuranceValue(primarypayorinsurance) : setPrimaryPayorInsuranceValue(null);
        }

        //populate primary funding type dropdown
        if (selectedClient) {
            var primaryfundingtype = credentialsContext?.fundingTypes?.find(
                (option) => Number(option.value) === selectedClient?.primaryPayorFundingTypeID
            );
            primaryfundingtype ? setPrimaryFundingTypeValue(primaryfundingtype) : setPrimaryFundingTypeValue(null);
        }

        //populate secondary payor insurance dropdown
        if (selectedClient) {
            var secondarypayorinsurance = credentialsContext?.companyInsurances?.find(
                (option) => Number(option.value) === selectedClient?.secondaryPayorInsuranceID
            );
            secondarypayorinsurance ? setSecondaryPayorInsuranceValue(secondarypayorinsurance) : setSecondaryPayorInsuranceValue(null);
        }

        //populate secondary funding type dropdown
        if (selectedClient) {
            var secondaryfundingtype = credentialsContext?.fundingTypes?.find(
                (option) => Number(option.value) === selectedClient?.secondaryPayorFundingTypeID
            );
            secondaryfundingtype ? setSecondaryFundingTypeValue(secondaryfundingtype) : setSecondaryFundingTypeValue(null);
        }

        //populate tertiary payor insurance dropdown
        if (selectedClient) {
            var tertiarypayorinsurance = credentialsContext?.companyInsurances?.find(
                (option) => Number(option.value) === selectedClient?.tertiaryPayorInsuranceID
            );
            tertiarypayorinsurance ? setTertiaryPayorInsuranceValue(tertiarypayorinsurance) : setTertiaryPayorInsuranceValue(null);
        }

        //populate tertiary funding type dropdown
        if (selectedClient) {
            var tertiaryfundingtype = credentialsContext?.fundingTypes?.find(
                (option) => Number(option.value) === selectedClient?.tertiaryPayorFundingTypeID
            );
            tertiaryfundingtype ? setTertiaryFundingTypeValue(tertiaryfundingtype) : setTertiaryFundingTypeValue(null);
        }

        //transition reason dropdown
        if (selectedClient) {
            var transitionreason = credentialsContext?.transitionReasons?.find(
                (option) => Number(option.value) === selectedClient?.lastDayOf97153ReasonID
            );
            transitionreason ? setTransitionReasonValue(transitionreason) : setTransitionReasonValue(null);
        }

        //discharge reason dropdown
        if (selectedClient) {
            var dischargereason = credentialsContext?.dischargeReasons?.find(
                (option) => Number(option.value) === selectedClient?.lastDayOfServiceReasonID
            );
            dischargereason ? setDischargeReasonValue(dischargereason) : setDischargeReasonValue(null);
        }

        //primary deductible dropdown
        if (selectedClient) {
            var primarydetuctible = DeductibleTypes.find(
                (option) => option.value === selectedClient?.primaryDeductibleType
            );
            primarydetuctible ? setPrimaryDeductibleValue(primarydetuctible) : setPrimaryDeductibleValue(null);
        }

        //primary out of pocket type dropdown
        if (selectedClient) {
            var primaryoutofpockettype = OutOfPocketTypes.find(
                (option) => option.value === selectedClient?.primaryOutOfPocketType
            );
            primaryoutofpockettype ? setPrimaryOutOfPocketTypeValue(primaryoutofpockettype) : setPrimaryOutOfPocketTypeValue(null);
        }

        //secondary deductible dropdown
        if (selectedClient) {
            var secondarydetuctible = DeductibleTypes.find(
                (option) => option.value === selectedClient?.secondaryDeductibleType
            );
            secondarydetuctible ? setSecondaryDeductibleValue(secondarydetuctible) : setSecondaryDeductibleValue(null);
        }

        //secondary out of pocket type dropdown
        if (selectedClient) {
            var secondaryoutofpockettype = OutOfPocketTypes.find(
                (option) => option.value === selectedClient?.secondaryOutOfPocketType
            );
            secondaryoutofpockettype ? setSecondaryOutOfPocketTypeValue(secondaryoutofpockettype) : setSecondaryOutOfPocketTypeValue(null);
        }

        //tertiary deductible dropdown
        if (selectedClient) {
            var tertiarydetuctible = DeductibleTypes.find(
                (option) => option.value === selectedClient?.tertiaryDeductibleType
            );
            tertiarydetuctible ? setTertiaryDeductibleValue(tertiarydetuctible) : setTertiaryDeductibleValue(null);
        }

        //tertiary out of pocket type dropdown
        if (selectedClient) {
            var tertiaryoutofpockettype = OutOfPocketTypes.find(
                (option) => option.value === selectedClient?.tertiaryOutOfPocketType
            );
            tertiaryoutofpockettype ? setTertiaryOutOfPocketTypeValue(tertiaryoutofpockettype) : setTertiaryOutOfPocketTypeValue(null);
        }


    }, [selectedClient]);

    function SelectClientByID(clientid: number) {
        //GetClientByID

        setLoading(true);

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Clients/GetLearnerInfoByID?clientid=" + clientid;

            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("raw client", result);
                        setSelectedClient(result);
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);

                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetLearnerInfoByID" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GetAllClientsList(IncludeInactive: boolean) {

        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Clients/GetAllClientsList?includeInactive=" + IncludeInactive;
            setLoading(true);
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setClients(result);
                        setLoading(false);
                        console.log("raw all clients:", result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAllClientsList" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })

    }

    function SaveLearner() {
        setLoading(true);

        var tempClientData = clientData;

        //get centerid from pod
        /*
        if (tempClientData) {
            var foundPod = globalContext.pods.find(x => x.podId == tempClientData?.podID);
            if (foundPod) {
                tempClientData.centerID = foundPod.centerId;
            }
        }
        */
        if (tempClientData) {
            tempClientData.therapyScheduleID = tScheduleId;
        }



        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(tempClientData)
            };

            var url = apiConfig.apiEndpoint + "/Clients/SaveNewClient";
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result: APIReturnObject) => {
                        if (result.status === Status.SUCCESS) {
                            GetAllClientsList(showInactiveLearners)
                            setSuccessModalActive(true);
                            setSelectedClient(null);
                            setSelectedClientChoice(null);
                            setIsAdding(false);
                            setLoading(false);
                        }
                        else if (result.status === Status.FAIL) {
                            setLoading(false);
                            globalContext.setNotificationModalMessage(result.message);
                            globalContext.setNotificationModalActive(true);
                        }
                        else if (result.status === Status.ERROR) {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: result.message, errorLocation: "/Clients/SaveNewClient" });
                            globalContext.setErrorModalActive(true);
                        }
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/SaveNewClient" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GetAuthorizationsFromEzClaim() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetEzClaimAuthoriazationsForClient?clientId=" + selectedClient?.clientID;
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setEzClaimAuthorizations(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAllClientsList" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GetAuthorizationsForClient() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetAuthorizationDetailsForClient?clientId=" + selectedClient?.clientID;
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setClientAuthorizations(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetAuthorizationDetailsForClient" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function GetPayorInfoTypes() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetPayorInfoTypes";
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setPayorInfoTypes(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetPayorInfoTypes" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    /* Get payor status  authorizationStatus*/

    function GetAuthorizationStatuses() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");

            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Credentials/GetAuthorizationStatuses";
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setAuthorizationStatuses(result);
                    },
                    (error) => {
                        setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/GetAuthorizationStatuses" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    function HandleImport() {

        if (reportDueDate) {
            var chosenImportedAuths = ezClaimAuthorizations.filter(x => x.authNumber == checkedAuthNumber);

            globalContext.GetToken().then((token: any) => {
                var headers = new Headers();
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                headers.append("Content-type", "application/json;charset=UTF-8");

                var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(chosenImportedAuths),
                };

                var url = apiConfig.apiEndpoint + "/Credentials/ImportAuthorizations?reportDueDate=" + new Date(reportDueDate).toDateString() + "&isBucket=" + (isBucket?.value === "1") + "&payorType=" + selectedPayorType?.value + "&authorizationStatus=" + selectedAuthStatus?.value + "&clientId=" + selectedClient?.clientID;
                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setAuthorizationStatuses(result);
                        },
                        (error) => {
                            setLoading(false);
                            globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Credentials/ImportAuthorizations" });
                            globalContext.setErrorModalActive(true);
                        }
                    )
            })
        }

    }

    function OnPayorTypeChange(input: SingleValue<ChoiceOption>) {

        if (payorInfoTypes) {

            var foundPayorType = payorInfoTypes?.find(z => String(z.payorInfoTypeId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundPayorType) {
                temp = ({ value: String(foundPayorType.payorInfoTypeId), label: foundPayorType.payorInfoType1 })
                setSelectedPayorType(temp);
            }
            HandleImportButtonActive();
        }

    }

    function OnAuthorizationStatusChange(input: SingleValue<ChoiceOption>) {
        if (authorizationStatuses) {
            var foundAuthorizationStatus = authorizationStatuses?.find(z => String(z.authStatusId) === String(input?.value));
            var temp: ChoiceOption;

            if (foundAuthorizationStatus) {
                temp = ({ value: String(foundAuthorizationStatus.authStatusId), label: foundAuthorizationStatus.status })
                setSelectedAuthStatus(temp);
            }
            HandleImportButtonActive();
        }
    }

    function GetClientById() {
        globalContext.GetToken().then((token: any) => {
            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            headers.append("Content-type", "application/json;charset=UTF-8");


            var options = {
                method: "GET",
                headers: headers,
            };

            var url = apiConfig.apiEndpoint + "/Clients/GetClientByID?clientId=" + selectedClientId;
            fetch(url, options)
                .then(res => res.json())
                .then(
                    (result) => {
                        setSelectedClientForModal(result);
                    },
                    (error) => {
                        //setLoading(false);
                        globalContext.setErrorModalProps({ errorMessage: error, errorLocation: "/Clients/GetClientByID" });
                        globalContext.setErrorModalActive(true);
                    }
                )
        })
    }

    // State to track the checked status of checkboxes based on authId
    const [checkedAuthNumber, setCheckedAuthNumber] = useState<string | null>(null);

    //Handle if the import button should be disabled or not
    const HandleImportButtonActive = () => {
        if (reportDueDate == null || isBucket == null || selectedPayorType == null || selectedAuthStatus == null || checkedAuthNumber == null) {
            setImportButtonActive(false);
        }
        else {
            setImportButtonActive(true);
        }

    }

    // Handle checkbox change
    const handleCheckboxChange = (authId: string) => {
        if (checkedAuthNumber == authId) {
            setCheckedAuthNumber(null);
        }
        else{
            setCheckedAuthNumber(authId);
        }
        
    };

    const OnImportCancel = () => {
        setAuthorizationContent("main");
        ClearValues();
    }

    const OnModalClose = () => {
        ClearValues();
    }

    function ClearValues() {
        setImportButtonActive(false);
        setReportDueDate(new Date());
        setIsBucket(null);
        setSelectedPayorType(null);
        setSelectedAuthStatus(null);

        setSelectedClientId(undefined);
        setSelectedAuthId(null);
        setSelectedClientForModal(null);
        setIsAuthorizationModalActive(false);
    }

    const HandleAuthorizationClick = (authId: string, clientId: number) => {
        setSelectedAuthId(authId);
        setSelectedClientId(clientId);
    }

    useEffect(() => {
        if (selectedClientId) {
            HandleImportButtonActive();
        }

    }, [checkedAuthNumber]);

    useEffect(() => {
        if (selectedClientId) {
            setIsAuthorizationModalActive(true);
        }

    }, [selectedAuthId]);

    // react select 32px height (yes this is trash)
    const customStyles = {
        control: (provided: any, state: { isFocused: any; }) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
        }),

        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '32px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '32px',
        }),
    };

    const SaveButton = () => {

        if (selectedClient && selectedTab !== 'authorization') {
            if (loading) {
                return (
                    <button disabled={true} className="mt-2 w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white bg-lacgreen-800">
                        <FontAwesomeIcon className="fill-current text-white w-5 h-5 animate-spin align-middle" icon={solid('spinner')} />
                        <span className="drop-shadow align-middle pl-1">Processing</span>
                    </button>
                );
            }
            else {
                return (<button onClick={SaveScheduleForClient} className={"bg-lacgreen mt-2 w-full w-[8rem] xl:w-[8rem] h-[3rem] xl:h-[3rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600"}><span className="drop-shadow">Save</span></button>)
            }
        }
        else {
            return <></>;
        }


    }

    function CopyMonday() {
        if (events && events.length > 0) {
            // Find the Monday event
            const monday: ScheduleEvent | undefined = events.find(x => x.resourceId == 2);

            if (monday) {
                const copiedEvents: ScheduleEvent[] = [];

                for (let i = 0; i < 5; i++) {
                    // Create a new ScheduleEvent based on the Monday event
                    const copiedEvent: ScheduleEvent = {
                        id: i + 2, // Assign a unique ID, adjust logic as needed
                        resourceId: i + 2, // Assign a new resource ID
                        start: new Date(monday.start!), // Ensure start is defined
                        startHour: monday.start!.getHours(),
                        startMinute: monday.start!.getMinutes(),
                        end: new Date(monday.end!), // Ensure end is defined
                        endHour: monday.end!.getHours(),
                        endMinute: monday.end!.getMinutes(),
                    };

                    // Add the copied event to the array
                    copiedEvents.push(copiedEvent);
                }

                setEvents(copiedEvents);
            }
        }
    }

    async function QuickSelectSchedule(schedule: SingleValue<ChoiceOption>) {
        //GetScheduleComponentsByID
        if (schedule) {
            const schedules: ScheduleEvent[] = await GetScheduleComponentsByID(Number(schedule.value));

            //ScheduleEvent
            var formattedListOfEvents = FormatEventsForCalendar(schedules);
            setEvents(formattedListOfEvents);
        }
    }


    async function GetScheduleComponentsByID(id: number) {

        const token = await globalContext.GetToken();

        var url = apiConfig.apiEndpoint + "/Credentials/GetScheduleComponentsByID?TherapyScheduleID=" + id;
        var headers = new Headers();
        var bearer = "Bearer " + token;

        headers.append("Authorization", bearer);
        var options = {
            method: "GET",
            headers: headers
        };

        try {
            const res = await fetch(url, options);
            const result = await res.json();
            return result;
        } catch (error) {
            globalContext.setErrorModalProps({ errorMessage: String(error), errorLocation: "/Credentials/GetScheduleComponentsByID" });
            globalContext.setErrorModalActive(true);
            return false;
        }
    }

    function formatPhoneNumber(phoneNumber: string | number | null | undefined): string {
        if (!phoneNumber) return "";
        const numStr = phoneNumber.toString();
        return numStr.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }

    return (

        <div className="flex-1 p-5">

            <div onClick={NavigateBack} className="flex items-center justify-center w-40 cursor-pointer col-span-2">
                <div className="flex-1">
                    <FontAwesomeIcon className="w-8 h-8 mr-auto align-middle" icon={solid('arrow-left')} />
                </div>
                <span className="text-xl w-auto ml-3">Back</span>
                <div className="flex-1"></div>
            </div>

            <div className={"grid grid-cols-2 h-fit mt-0 mb-auto xl:w-[80rem] w-full m-auto " + (loading ? "hidden" : "")}>

                <div className={"w-full m-auto xl:col-span-1 col-span-2 " + (isAdding ? "hidden" : "")}>

                    <div className={"w-fit ml-auto"}>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" onChange={event => setShowInactiveLearners(event.target.checked)} className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                            <span className="ml-3 text-sm font-medium">Show All Learners</span>
                        </label>
                    </div>

                    <div className={"grid grid-cols-12"}>
                        <div className={"col-span-11"}>
                            <label className="block font-bold" htmlFor="centers">
                                Learner Selection:
                            </label>
                            <Select className={"outline-0"} options={clients ? clients : undefined} onChange={setSelectedClientChoice} value={selectedClientChoice} />

                        </div>
                        <div className={"col-span-1 mt-auto pl-2"}>
                            <div className="w-fit h-fit cursor-pointer" onClick={() => setIsAdding(true)}>
                                <FontAwesomeIcon className="w-8 h-8 text-lacgreen transition duration-250 ease-in-out hover:text-lacgreen-600" icon={solid('circle-plus')} />
                            </div>
                        </div>

                    </div>


                </div>

                <div className={"grid grid-cols-2 xl:col-span-1 col-span-2 " + (selectedClient ? "" : "hidden ") + (isAdding ? "hidden" : "")}>
                    <div className="xl:ml-auto my-auto pr-4">
                        <ProfilePictureSmall ClientID={selectedClient?.clientID} FirstName={selectedClient?.learnerFirstName} LastName={selectedClient?.learnerLastName} />
                    </div>

                    <table className={"w-fit text-sm"}>
                        <tbody>
                            <tr>
                                <td><span className="font-bold">Learner Name:</span></td>
                                <td><span className="text-bold ml-2">{selectedClient?.learnerFirstName + " " + selectedClient?.learnerLastName}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Birthday:</span></td>
                                <td><span className="text-bold ml-2">{selectedClient?.learnerDOB ? new Date(selectedClient?.learnerDOB).toLocaleDateString() : ""}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Client ID:</span></td>
                                <td><span className="text-bold ml-2">{selectedClient?.clientID}</span></td>
                            </tr>
                            <tr>
                                <td><span className="font-bold">Center:</span></td>
                                <td><span className="text-bold ml-2">{selectedClient?.centerName}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={"xl:w-[80rem] w-full m-auto col-span-2 " + (selectedClient ?? "hidden")}>

                    <button onClick={() => setSelectedTab("profile")} className={"block hover:text-lacblue mr-4 transition duration-250 ease-in-out mt-4 inline-block mt-0 text-xl " + (selectedTab == "profile" ? "text-lacblue border-b-2 border-lacblue font-bold " : "")}>
                        <FontAwesomeIcon className="fill-current text-xl cursor-pointer mr-2" icon={solid('circle-user')} />
                        Profile
                    </button>

                    <button onClick={() => setSelectedTab("payor")} className={"block hover:text-lacblue mr-4 transition duration-250 ease-in-out mt-4 inline-block mt-0 text-xl " + (selectedTab == "payor" ? "text-lacblue border-b-2 border-lacblue font-bold " : "")}>
                        <FontAwesomeIcon className="fill-current text-xl cursor-pointer mr-2" icon={solid('building-columns')} />
                        Primary Payor
                    </button>

                    <button onClick={() => setSelectedTab("secondarypayor")} className={"block hover:text-lacblue mr-4 transition duration-250 ease-in-out mt-4 inline-block mt-0 text-xl " + (selectedTab == "secondarypayor" ? "text-lacblue border-b-2 border-lacblue font-bold " : "")}>
                        <FontAwesomeIcon className="fill-current text-xl cursor-pointer mr-2" icon={solid('building-columns')} />
                        Secondary Payor
                    </button>

                    <button onClick={() => setSelectedTab("tertiarypayor")} className={"block hover:text-lacblue mr-4 transition duration-250 ease-in-out mt-4 inline-block mt-0 text-xl " + (selectedTab == "tertiarypayor" ? "text-lacblue border-b-2 border-lacblue font-bold " : "")}>
                        <FontAwesomeIcon className="fill-current text-xl cursor-pointer mr-2" icon={solid('building-columns')} />
                        Tertiary Payor
                    </button>

                    <button onClick={() => setSelectedTab("schedule")} className={"block hover:text-lacblue mr-4 transition duration-250 ease-in-out mt-4 inline-block mt-0 text-xl " + (selectedTab == "schedule" ? "text-lacblue border-b-2 border-lacblue font-bold " : "")}>
                        <FontAwesomeIcon className="fill-current text-xl cursor-pointer mr-2" icon={solid('calendar-days')} />
                        Schedule
                    </button>
                    {/*Authorizations.InsuranceAccess*/}
                    {/*
                        <ProtectedComponent RequiredRoles={["Authorizations.InsuranceAccess"]} >
                            <button onClick={() => setSelectedTab("authorization")} className={"block hover:text-lacblue mr-4 transition duration-250 ease-in-out mt-4 inline-block mt-0 text-xl " + (selectedTab == "authorization" ? "text-lacblue border-b-2 border-lacblue font-bold " : "")}>
                                <FontAwesomeIcon className="fill-current text-xl cursor-pointer mr-2" icon={solid('clipboard-check')} />
                                Authorization
                            </button>
                        </ProtectedComponent>
                    */}
                </div>

                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-4 m-auto border border-2 mt-2 grid grid-cols-12 gap-x-4 gap-y-2 " + (selectedClient && selectedTab == "profile" ? "" : "hidden")}>

                    <span className="col-span-12 text-lg m-auto font-bold">Therapy Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Center:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <Select onChange={setCenterValue} value={centerValue} styles={customStyles} options={globalContext && globalContext.centerChoices ? globalContext.centerChoices : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Pod:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <Select onChange={setPodValue} value={podValue} styles={customStyles} options={globalContext && globalContext.podChoices ? globalContext.podChoices : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Start Date:
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.learnerStartDate}
                            onChange={(date) => handleChange({ name: 'learnerStartDate' }, date)}
                        />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Learner Active:
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input name={"learnerStatus"} type="checkbox" onChange={handleChange} checked={clientData?.learnerStatus} className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            RBT Required:
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input name={"rbtRequired"} type="checkbox" onChange={handleChange} checked={clientData?.rbtRequired} className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Telehealth Allowed:
                        </label>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input name={"telehealthAvailable"} type="checkbox" onChange={handleChange} checked={clientData?.telehealthAvailable} className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                        </label>
                    </div>

                    <hr className="col-span-12 font-xl"></hr>

                    <span className="col-span-12 text-lg m-auto font-bold">Learner Contact Info:</span>


                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>First:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"learnerFirstName"} value={clientData?.learnerFirstName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-1">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Middle:</span>
                        </label>
                        <input
                            name={"learnerMiddleName"}
                            value={clientData?.learnerMiddleName ?? ""}
                            onChange={handleChange}
                            autoComplete="off"
                            type="text"
                            className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5"
                        />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Last:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"learnerLastName"} value={clientData?.learnerLastName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Date of Birth:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={true}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.learnerDOB}
                            onChange={(date) => handleChange({ name: 'learnerDOB' }, date)}
                        />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Street Address:
                        </label>
                        <input name={"learnerAddress"} value={clientData?.learnerAddress ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Apt, suite, unit, building floor, etc:
                        </label>
                        <input name={"learnerAddress2"} value={clientData?.learnerAddress2 ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            City:
                        </label>
                        <input name={"learnerCity"} value={clientData?.learnerCity ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            State:
                        </label>
                        <input name={"learnerState"} value={clientData?.learnerState ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Zip Code:
                        </label>
                        <input name={"learnerZipCode"} value={clientData?.learnerZipCode ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <hr className="col-span-12 font-xl"></hr>



                    <span className="col-span-12 text-lg m-auto font-bold">Primary Contact Info:</span>



                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Relationship:
                        </label>
                        <Select isClearable={true} onChange={setPrimaryRelationshipValue} value={primaryRelationshipValue} styles={customStyles} options={credentialsContext && credentialsContext.clientRelationships ? credentialsContext.clientRelationships : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            First:
                        </label>
                        <input name={"primaryContactFirstName"} value={clientData?.primaryContactFirstName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Last:
                        </label>
                        <input name={"primaryContactLastName"} value={clientData?.primaryContactLastName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Phone:
                        </label>
                        
                        <InputMask
                            mask="(___) ___-____"
                            replacement={{ _: /\d/ }}
                            name="primaryContactPhoneNumber"
                            value={formatPhoneNumber(clientData?.primaryContactPhoneNumber) ?? ""}
                            onChange={handleChange}
                            className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5"
                            placeholder="(123) 456-7890"
                        />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Email:
                        </label>
                        <input name={"primaryContactEmail"} value={clientData?.primaryContactEmail ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    

                    <div className={"col-span-12"}>
                        <button onClick={CopyLearnerToContact} className={"bg-purple w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"}><span className="drop-shadow">Copy learner address</span></button>
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm">
                            Street Address:
                        </label>
                        <input name={"primaryContactAddress"} value={clientData?.primaryContactAddress ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm">
                            Apt, suite, unit, building floor, etc:
                        </label>
                        <input name={"primaryContactAddress2"} value={clientData?.primaryContactAddress2 ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm">
                            City:
                        </label>
                        <input name={"primaryContactCity"} value={clientData?.primaryContactCity ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm">
                            State:
                        </label>
                        <input name={"primaryContactState"} value={clientData?.primaryContactState ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm">
                            Zip Code:
                        </label>
                        <input name={"primaryContactZipCode"} value={clientData?.primaryContactZipCode ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <hr className="col-span-12 font-xl"></hr>


                    

                    <span className="col-span-12 text-lg m-auto font-bold">Transition Info:</span>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Last Day of 97153:
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.lastDayOf97153}
                            onChange={(date) => handleChange({ name: 'lastDayOf97153' }, date)}
                        />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Reason:
                        </label>
                        <Select isClearable={true} onChange={setTransitionReasonValue} value={transitionReasonValue} styles={customStyles} options={credentialsContext && credentialsContext.transitionReasons ? credentialsContext.transitionReasons : undefined} />
                    </div>

                    <hr className="col-span-12 font-xl"></hr>

                    <span className="col-span-12 text-lg m-auto font-bold">Discharge Info:</span>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Last Day of Service:
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.lastDayOfService}
                            onChange={(date) => handleChange({ name: 'lastDayOfService' }, date)}
                        />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Reason:
                        </label>
                        <Select isClearable={true} onChange={setDischargeReasonValue} value={dischargeReasonValue} styles={customStyles} options={credentialsContext && credentialsContext.dischargeReasons ? credentialsContext.dischargeReasons : undefined} />
                    </div>

                </div>

                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-4 m-auto border border-2 mt-2 grid grid-cols-12 gap-x-4 gap-y-2 " + (selectedClient && selectedTab == "payor" ? "" : "hidden")}>

                    <span className="col-span-12 text-lg m-auto font-bold">Primary Subscriber Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>First:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primarySubscriberFirstName"} value={clientData?.primarySubscriberFirstName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-1">
                        <label className="block font-bold text-sm">
                            Middle:
                        </label>
                        <input name={"primarySubscriberMiddleName"} value={clientData?.primarySubscriberMiddleName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Last:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primarySubscriberLastName"} value={clientData?.primarySubscriberLastName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Date of Birth:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={true}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.primarySubscriberDOB}
                            onChange={(date) => handleChange({ name: 'primarySubscriberDOB' }, date)}
                        />
                    </div>

                    <div className={"col-span-12"}>
                        <button onClick={CopyLearnerToPrimary} className={"bg-purple w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"}><span className="drop-shadow">Copy learner address</span></button>
                        <button onClick={CopyContactToPrimary} className={"bg-cyan ml-4 w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-cyan-600"}><span className="drop-shadow">Copy primary contact address</span></button>
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Street Address:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primarySubscriberAddress"} value={clientData?.primarySubscriberAddress ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm">
                            Apt, suite, unit, building floor, etc:
                        </label>
                        <input name={"primarySubscriberAddress2"} value={clientData?.primarySubscriberAddress2 ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>City:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primarySubscriberCity"} value={clientData?.primarySubscriberCity ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>State:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primarySubscriberState"} value={clientData?.primarySubscriberState ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Zip Code:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primarySubscriberZipCode"} value={clientData?.primarySubscriberZipCode ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <hr className="col-span-12 font-xl"></hr>

                    <span className="col-span-12 text-lg m-auto font-bold">Primary Payor Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Insurance Name:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <Select isClearable={true} onChange={setPrimaryPayorInsuranceValue} value={primaryPayorInsuranceValue} styles={customStyles} options={credentialsContext && credentialsContext.companyInsurances ? credentialsContext.companyInsurances : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Subscriber ID #:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <input name={"primaryPayorSubscriberID"} value={clientData?.primaryPayorSubscriberID ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Funding Type:</span>
                            <span className="text-red-500 text-sm">*Required</span>
                        </label>
                        <Select isClearable={true} onChange={setPrimaryFundingTypeValue} value={primaryFundingTypeValue} styles={customStyles} options={credentialsContext && credentialsContext.fundingTypes ? credentialsContext.fundingTypes : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Copay:
                        </label>
                        <input type={"number"} name={"primaryCopay"} value={clientData?.primaryCopay ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4 items-center flex mt-auto h-fit focus:ring-lacblue focus:border-lacblue block w-full">

                        <label className="inline-flex items-center w-[50%]">
                            <input type="radio" className="form-radio text-blue-500" name="primaryCopayFrequency" value={'day'} checked={'day' == clientData?.primaryCopayFrequency} onClick={handleRadioChange} />
                            <span className="ml-2">Copay Per Day</span>
                        </label>

                        <label className="inline-flex items-center  w-[50%]">
                            <input type="radio" className="form-radio text-blue-500" name="primaryCopayFrequency" value={'service'} checked={'service' == clientData?.primaryCopayFrequency} onClick={handleRadioChange} />
                            <span className="ml-2">Copay Per Service</span>
                        </label>

                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            CoInsurance:
                        </label>
                        <input name={"primaryCoinsurance"} value={clientData?.primaryCoinsurance ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>



                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Deductible:
                        </label>
                        <input type={"number"} name={"primaryDeductible"} value={clientData?.primaryDeductible ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Deductible Type:
                        </label>
                        <Select isClearable={true} onChange={setPrimaryDeductibleValue} value={primaryDeductibleValue} styles={customStyles} options={DeductibleTypes} />
                    </div>


                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Out of Pocket Maximum:
                        </label>
                        <input type={"number"} name={"primaryOutOfPocketMax"} value={clientData?.primaryOutOfPocketMax ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Out of Pocket Maximum Type:
                        </label>
                        <Select isClearable={true} onChange={setPrimaryOutOfPocketTypeValue} value={primaryOutOfPocketTypeValue} styles={customStyles} options={OutOfPocketTypes} />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Effective Date:
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.primaryEffectiveDate}
                            onChange={(date) => handleChange({ name: 'primaryEffectiveDate' }, date)}
                        />
                    </div>

                    <div className="col-span-6 grid grid-cols-12">
                        <label className="block font-bold col-span-12 text-sm">
                            Benefit Period:
                        </label>
                        <DatePicker
                            className="w-full bg-white col-span-5"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.primaryBenefitPeriodStart}
                            onChange={(date) => handleChange({ name: 'primaryBenefitPeriodStart' }, date)}
                        />
                        <span className="col-span-2 m-auto">to</span>
                        <DatePicker
                            className="w-full bg-white col-span-5"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.primaryBenefitPeriodEnd}
                            onChange={(date) => handleChange({ name: 'primaryBenefitPeriodEnd' }, date)}
                        />
                    </div>

                </div>

                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-4 m-auto border border-2 mt-2 grid grid-cols-12 gap-x-4 gap-y-2 " + (selectedClient && selectedTab == "secondarypayor" ? "" : "hidden")}>

                    <span className="col-span-12 text-lg m-auto font-bold">Secondary Subscriber Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>First:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <input name={"secondarySubscriberFirstName"} value={clientData?.secondarySubscriberFirstName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-1">
                        <label className="block font-bold text-sm">
                            Middle:
                        </label>
                        <input name={"secondarySubscriberMiddleName"} value={clientData?.secondarySubscriberMiddleName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Last:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <input name={"secondarySubscriberLastName"} value={clientData?.secondarySubscriberLastName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Date of Birth:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={true}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.secondarySubscriberDOB}
                            onChange={(date) => handleChange({ name: 'secondarySubscriberDOB' }, date)}
                        />
                    </div>

                    <div className={"col-span-12"}>
                        <button onClick={CopyLearnerToSecondary} className={"bg-purple w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"}><span className="drop-shadow">Copy learner address</span></button>
                        <button onClick={CopyContactToSecondary} className={"bg-cyan ml-4 w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-cyan-600"}><span className="drop-shadow">Copy primary contact address</span></button>
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Street Address:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <input name={"secondarySubscriberAddress"} value={clientData?.secondarySubscriberAddress ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm">
                            Apt, suite, unit, building floor, etc:
                        </label>
                        <input name={"secondarySubscriberAddress2"} value={clientData?.secondarySubscriberAddress2 ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>City:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <input name={"secondarySubscriberCity"} value={clientData?.secondarySubscriberCity ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>State:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <input name={"secondarySubscriberState"} value={clientData?.secondarySubscriberState ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm">
                            <label className="block font-bold text-sm flex justify-between items-center">
                                <span>Zip Code:</span>
                                <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                            </label>
                        </label>
                        <input name={"secondarySubscriberZipCode"} value={clientData?.secondarySubscriberZipCode ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <hr className="col-span-12 font-xl"></hr>

                    <span className="col-span-12 text-lg m-auto font-bold">Secondary Payor Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Insurance Name:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <Select isClearable={true} onChange={setSecondaryPayorInsuranceValue} value={secondaryPayorInsuranceValue} styles={customStyles} options={credentialsContext && credentialsContext.companyInsurances ? credentialsContext.companyInsurances : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Subscriber ID #:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <input name={"secondaryPayorSubscriberID"} value={clientData?.secondaryPayorSubscriberID ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Funding Type:</span>
                            <span className={"text-red-500 text-sm " + (!hasSecondary && "hidden")}>*Required</span>
                        </label>
                        <Select isClearable={true} onChange={setSecondaryFundingTypeValue} value={secondaryFundingTypeValue} styles={customStyles} options={credentialsContext && credentialsContext.fundingTypes ? credentialsContext.fundingTypes : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Copay:
                        </label>
                        <input type={"number"} name={"secondaryCopay"} value={clientData?.secondaryCopay ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4 items-center flex mt-auto h-fit focus:ring-lacblue focus:border-lacblue block w-full">

                        <label className="inline-flex items-center w-[50%]">
                            <input type="radio" className="form-radio text-blue-500" name="secondaryCopayFrequency" value={'day'} checked={'day' == clientData?.secondaryCopayFrequency} onClick={handleRadioChange} />
                            <span className="ml-2">Copay Per Day</span>
                        </label>

                        <label className="inline-flex items-center  w-[50%]">
                            <input type="radio" className="form-radio text-blue-500" name="secondaryCopayFrequency" value={'service'} checked={'service' == clientData?.secondaryCopayFrequency} onClick={handleRadioChange} />
                            <span className="ml-2">Copay Per Service</span>
                        </label>

                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            CoInsurance:
                        </label>
                        <input name={"secondaryCoinsurance"} value={clientData?.secondaryCoinsurance ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Deductible:
                        </label>
                        <input type={"number"} name={"secondaryDeductible"} value={clientData?.secondaryDeductible ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Deductible Type:
                        </label>
                        <Select isClearable={true} onChange={setSecondaryDeductibleValue} value={secondaryDeductibleValue} styles={customStyles} options={DeductibleTypes} />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Out of Pocket Maximum:
                        </label>
                        <input type={"number"} name={"secondaryOutOfPocketMax"} value={clientData?.secondaryOutOfPocketMax ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Out of Pocket Maximum Type:
                        </label>
                        <Select isClearable={true} onChange={setSecondaryOutOfPocketTypeValue} value={secondaryOutOfPocketTypeValue} styles={customStyles} options={OutOfPocketTypes} />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Effective Date:
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.secondaryEffectiveDate}
                            onChange={(date) => handleChange({ name: 'secondaryEffectiveDate' }, date)}
                        />
                    </div>

                    <div className="col-span-6 grid grid-cols-12">
                        <label className="block font-bold col-span-12 text-sm">
                            Benefit Period:
                        </label>
                        <DatePicker
                            className="w-full bg-white col-span-5"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.secondaryBenefitPeriodStart}
                            onChange={(date) => handleChange({ name: 'secondaryBenefitPeriodStart' }, date)}
                        />
                        <span className="col-span-2 m-auto">to</span>
                        <DatePicker
                            className="w-full bg-white col-span-5"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.secondaryBenefitPeriodEnd}
                            onChange={(date) => handleChange({ name: 'secondaryBenefitPeriodEnd' }, date)}
                        />
                    </div>

                </div>

                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-4 m-auto border border-2 mt-2 grid grid-cols-12 gap-x-4 gap-y-2 " + (selectedClient && selectedTab == "tertiarypayor" ? "" : "hidden")}>

                    <span className="col-span-12 text-lg m-auto font-bold">Tertiary Subscriber Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>First:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiarySubscriberFirstName"} value={clientData?.tertiarySubscriberFirstName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-1">
                        <label className="block font-bold text-sm">
                            Middle:
                        </label>
                        <input name={"tertiarySubscriberMiddleName"} value={clientData?.tertiarySubscriberMiddleName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Last:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiarySubscriberLastName"} value={clientData?.tertiarySubscriberLastName ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Date of Birth:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={true}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.tertiarySubscriberDOB}
                            onChange={(date) => handleChange({ name: 'tertiarySubscriberDOB' }, date)}
                        />
                    </div>

                    <div className={"col-span-12"}>
                        <button onClick={CopyLearnerToTertiary} className={"bg-purple w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-purple-600"}><span className="drop-shadow">Copy learner address</span></button>
                        <button onClick={CopyContactToTertiary} className={"bg-cyan ml-4 w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-cyan-600"}><span className="drop-shadow">Copy primary contact address</span></button>
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Street Address:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiarySubscriberAddress"} value={clientData?.tertiarySubscriberAddress ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-6"}>
                        <label className="block font-bold text-sm">
                            Apt, suite, unit, building floor, etc:
                        </label>
                        <input name={"tertiarySubscriberAddress2"} value={clientData?.tertiarySubscriberAddress2 ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>City:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiarySubscriberCity"} value={clientData?.tertiarySubscriberCity ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>State:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiarySubscriberState"} value={clientData?.tertiarySubscriberState ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className={"col-span-4"}>
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Zip Code:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiarySubscriberZipCode"} value={clientData?.tertiarySubscriberZipCode ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <hr className="col-span-12 font-xl"></hr>

                    <span className="col-span-12 text-lg m-auto font-bold">Tertiary Payor Info:</span>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Insurance Name:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <Select isClearable={true} onChange={setTertiaryPayorInsuranceValue} value={tertiaryPayorInsuranceValue} styles={customStyles} options={credentialsContext && credentialsContext.companyInsurances ? credentialsContext.companyInsurances : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Subscriber ID #:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <input name={"tertiaryPayorSubscriberID"} value={clientData?.tertiaryPayorSubscriberID ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm flex justify-between items-center">
                            <span>Funding Type:</span>
                            <span className={"text-red-500 text-sm " + (!hasTertiary && "hidden")}>*Required</span>
                        </label>
                        <Select isClearable={true} onChange={setTertiaryFundingTypeValue} value={tertiaryFundingTypeValue} styles={customStyles} options={credentialsContext && credentialsContext.fundingTypes ? credentialsContext.fundingTypes : undefined} />
                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            Copay:
                        </label>
                        <input type={"number"} name={"tertiaryCopay"} value={clientData?.tertiaryCopay ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-4 items-center flex mt-auto h-fit focus:ring-lacblue focus:border-lacblue block w-full">

                        <label className="inline-flex items-center w-[50%]">
                            <input type="radio" className="form-radio text-blue-500" name="tertiaryCopayFrequency" value={'day'} checked={'day' == clientData?.tertiaryCopayFrequency} onClick={handleRadioChange} />
                            <span className="ml-2">Copay Per Day</span>
                        </label>

                        <label className="inline-flex items-center  w-[50%]">
                            <input type="radio" className="form-radio text-blue-500" name="tertiaryCopayFrequency" value={'service'} checked={'service' == clientData?.tertiaryCopayFrequency} onClick={handleRadioChange} />
                            <span className="ml-2">Copay Per Service</span>
                        </label>

                    </div>

                    <div className="col-span-4">
                        <label className="block font-bold text-sm">
                            CoInsurance:
                        </label>
                        <input name={"tertiaryCoinsurance"} value={clientData?.tertiaryCoinsurance ?? ""} onChange={handleChange} autoComplete="off" type="text" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>


                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Deductible:
                        </label>
                        <input type={"number"} name={"tertiaryDeductible"} value={clientData?.tertiaryDeductible ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Deductible Type:
                        </label>
                        <Select isClearable={true} onChange={setTertiaryDeductibleValue} value={tertiaryDeductibleValue} styles={customStyles} options={DeductibleTypes} />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Out of Pocket Maximum:
                        </label>
                        <input type={"number"} name={"tertiaryOutOfPocketMax"} value={clientData?.tertiaryOutOfPocketMax ?? ""} onChange={handleChange} autoComplete="off" className="outline-none h-[32px] text-sm hover:border-black border focus:border-2 border-gray-300 text-gray-900 rounded focus:ring-lacblue focus:border-lacblue block w-full p-2.5" />
                    </div>

                    <div className="col-span-3">
                        <label className="block font-bold text-sm">
                            Out of Pocket Maximum Type:
                        </label>
                        <Select isClearable={true} onChange={setTertiaryOutOfPocketTypeValue} value={tertiaryOutOfPocketTypeValue} styles={customStyles} options={OutOfPocketTypes} />
                    </div>

                    <div className="col-span-6">
                        <label className="block font-bold text-sm">
                            Effective Date:
                        </label>
                        <DatePicker
                            className="w-full bg-white"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.tertiaryEffectiveDate}
                            onChange={(date) => handleChange({ name: 'tertiaryEffectiveDate' }, date)}
                        />
                    </div>

                    <div className="col-span-6 grid grid-cols-12">
                        <label className="block font-bold col-span-12 text-sm">
                            Benefit Period:
                        </label>
                        <DatePicker
                            className="w-full bg-white col-span-5"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.tertiaryBenefitPeriodStart}
                            onChange={(date) => handleChange({ name: 'tertiaryBenefitPeriodStart' }, date)}
                        />
                        <span className="col-span-2 m-auto">to</span>
                        <DatePicker
                            className="w-full bg-white col-span-5"
                            disableFuture={false}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontSize: '14px', height: '32px' }, // Apply inline styles directly
                                    }}
                                />
                            )}
                            value={clientData?.tertiaryBenefitPeriodEnd}
                            onChange={(date) => handleChange({ name: 'tertiaryBenefitPeriodEnd' }, date)}
                        />
                    </div>

                </div>

                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-2 m-auto border border-2 mt-2 gap-2 " + (selectedClient && selectedTab == "schedule" ? "" : "hidden")}>
                    <div className="grid grid-cols-12 mb-2">
                        <div className="col-span-3">
                            <label className="block font-bold text-sm">
                                Quick Pick Schedule:
                            </label>
                            <Select onChange={(newValue) => {QuickSelectSchedule(newValue)}} isClearable={false} styles={customStyles} options={topScheduleChoices ?? []} />
                        </div>

                        <div className="col-span-6"></div>


                        <div className="col-span-3">
                            <label className="block font-bold text-sm">
                                Copy Monday to Entire Week:
                            </label>
                            <button onClick={CopyMonday} className={"bg-cyan w-[18rem] h-[2rem] rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-cyan-600"}><span className="drop-shadow">Copy Monday</span></button>
                        </div>

                    </div>
                    
                    <MyCalendar></MyCalendar>
                </div>

                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-2 m-auto border border-2 mt-2 gap-2 " + (selectedClient && selectedTab == "authorization" && authorizationContent == "main" ? "" : "hidden")}>
                    <div className="ml-auto p-2 border-2 border-lacblue rounded-lg">

                        <div className="w-full flex my-1 items-center">
                            <h2 className="text-2xl text-lacblue w-fit">Current Authorization</h2>

                            {/* The container for the buttons with ml-auto to push it to the right */}
                            <div className="ml-auto flex items-center text-right pr-2">
                                <button className="bg-rose mx-2 my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600">
                                    <span className="drop-shadow">Add Full Denial</span>
                                </button>

                                <button onClick={() => setAuthorizationContent("import")} className="bg-lacgreen mx-2 my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600">
                                    <span className="drop-shadow">Import Authorization</span>
                                </button>
                            </div>
                        </div>

                        <table className="table-auto w-full">
                            <thead className="w-full border border-lacblue-500">
                                <tr className="bg-lacblue text-white">
                                    <th>Auth #</th>
                                    <th>Code</th>
                                    <th>Units Req.</th>
                                    <th>Units Apv.</th>
                                    <th>Mod 1</th>
                                    <th>Mod 2</th>
                                    <th>Authorized BCBA</th>
                                    <th>Payor Type</th>
                                    <th>Classification</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Report Due</th>
                                    <th>Auth Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Build out dependent on the view */}

                                {clientAuthorizations && clientAuthorizations.length > 0 ? clientAuthorizations.map(authorization => 
                                    <tr className="border border-gray-500 text-center">
                                        <td className="border border-gray-500"><span>{authorization.authNumber}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.cptcode}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.unitsRequested}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.unitsAllowed}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.mod1}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.mod2}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.providerName}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.payorName}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.payorInfoType}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.startDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.endDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.endDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.authNumber}</span></td>
                                        <td onClick={e => HandleAuthorizationClick(authorization.authId, authorization.clientId)} className="border border-gray-500"><FontAwesomeIcon className="float-right text-lacblue w-7 h-7 m-auto hover:cursor-pointer" icon={solid('circle-info')} ></FontAwesomeIcon></td>
                                    </tr>
                                ) : <></>}
                            </tbody>
                        </table>
                    </div>

                    <div className="ml-auto p-2 border-2 border-lacblue rounded-lg mt-5">
                        <div className="w-full flex items-center justify-between">

                            <h2 className="text-2xl text-lacblue w-fit">Past Authorizations</h2>

                        </div>

                        <table className="table-auto w-full">
                            <thead className="w-full border border-lacblue-500">
                                <tr className="bg-lacblue text-white">
                                    <th>Auth #</th>
                                    <th>Code</th>
                                    <th>Units Req.</th>
                                    <th>Units Apv.</th>
                                    <th>Mod 1</th>
                                    <th>Mod 2</th>
                                    <th>Authorized BCBA</th>
                                    <th>Payor Type</th>
                                    <th>Classification</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Report Due</th>
                                    <th>Auth Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Build out dependent on the view */}
                                {clientAuthorizations && clientAuthorizations.length > 0 ? clientAuthorizations.map(authorization =>
                                    <tr className="border border-gray-500 text-center">
                                        <td className="border border-gray-500"><span>{authorization.authNumber}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.cptcode}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.unitsRequested}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.unitsAllowed}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.mod1}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.mod2}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.providerName}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.payorName}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.payorInfoType}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.startDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.endDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.endDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.authNumber}</span></td>
                                        <td onClick={e => HandleAuthorizationClick(authorization.authId, authorization.clientId)} className="border border-gray-500"><FontAwesomeIcon className="float-right text-lacblue w-7 h-7 m-auto hover:cursor-pointer" icon={solid('circle-info')} ></FontAwesomeIcon></td>
                                    </tr>
                                ) : <></>}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* authorization - import content */}
                <div className={"xl:w-[80rem] col-span-2 w-full bg-gray-100 p-2 m-auto border border-2 mt-2 gap-2 " + (selectedClient && selectedTab == "authorization" && authorizationContent == "import" ? "" : "hidden")}>
                    <div className="ml-auto p-2 border-2 border-lacblue rounded-lg">
                        <div className="w-full flex my-1 items-center justify-start">

                            <h2 className="text-2xl text-lacblue w-fit">EZ Claim Authorizations</h2>

                        </div>

                        <table className="table-auto w-full">
                            <thead className="w-full border border-lacblue-500">
                                <tr className="bg-lacblue text-white">
                                    <th>Select</th>
                                    <th>Auth #</th>
                                    <th>Code</th>
                                    <th>Units Req.</th>
                                    <th>Units Apv.</th>
                                    <th>Mod 1</th>
                                    <th>Mod 2</th>
                                    <th>Authorized BCBA</th>
                                    <th>Payor</th>
                                    <th>Classification</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Build out dependent on the view */}

                                {ezClaimAuthorizations && clientAuthorizations.length > 0 ? ezClaimAuthorizations.map(authorization =>
                                    <tr className="border border-gray-500 text-center">
                                        <td className="border border-gray-500"><input type="checkbox" checked={authorization.authNumber == checkedAuthNumber || false} onChange={() => handleCheckboxChange(authorization.authNumber)} /></td>
                                        <td className="border border-gray-500"><span>{authorization.authNumber}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.procedureCode}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.unitsAllowed}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.unitsAllowed}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.mod1}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.mod2}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.phyName}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.payerName}</span></td>
                                        <td className="border border-gray-500"><span>classification</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.startDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{new Date(authorization.endDate).toLocaleDateString()}</span></td>
                                        <td className="border border-gray-500"><span>{authorization.notes}</span></td>
                                    </tr>
                                ) : <></>}
                            </tbody>
                        </table>

                        

                    </div>
                    {/* Bottom section */}
                    <div className="grid gap-2 grid-cols-12 xl:w-[60rem] w-full m-auto mt-4 mb-8">

                        <div className="w-full m-auto sm:col-span-3 col-span-3">
                            <label className="block font-bold" htmlFor="search">
                                Report Due Date
                            </label>
                            <DatePicker
                                value={reportDueDate}
                                disabled={false}
                                disableFuture={false}
                                onChange={(newValue) => {
                                    setReportDueDate(newValue);
                                    HandleImportButtonActive();
                                }}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </div>
                        <div className="w-full m-auto sm:col-span-3 col-span-3">
                            <label className="block font-bold" htmlFor="search">
                                Bucket Information
                            </label>
                            <Select
                                className=""
                                components={{ ValueContainer }}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                options={[
                                    { value: "1", label: "Bucket" },
                                    { value: "0", label: "Non-Bucket" }
                                ]}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setIsBucket(newValue);
                                        HandleImportButtonActive();
                                    }
                                    else {
                                        setIsBucket(null);
                                        HandleImportButtonActive();
                                    }
                                }}
                                value={isBucket}
                            />
                        </div>
                        <div className="w-full m-auto sm:col-span-3 col-span-3">
                            <label className="block font-bold" htmlFor="search">
                                Payor Type
                            </label>
                            <Select options={payorInfoTypes && payorInfoTypes.length > 0 ? payorInfoTypes.map((payor) => ({ value: String(payor.payorInfoTypeId), label: payor.payorInfoType1 })) : undefined} onChange={OnPayorTypeChange} value={selectedPayorType} />
                        </div>
                        <div className="w-full m-auto sm:col-span-3 col-span-3">
                            <label className="block font-bold" htmlFor="search">
                                Authorization Status
                            </label>
                            <Select options={authorizationStatuses && authorizationStatuses.length > 0 ? authorizationStatuses.map((authorizationStatus) => ({ value: String(authorizationStatus.authStatusId), label: authorizationStatus.status })) : undefined} onChange={OnAuthorizationStatusChange} value={selectedAuthStatus} />
                        </div>


                    </div>

                    <div className="w-full flex my-1 items-center justify-center">
                        <button onClick={() => OnImportCancel()} className="bg-rose mx-5 my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-rose-600">
                            <span className="drop-shadow">Cancel</span>
                        </button>

                        {/*importButtonActive*/}
                        <button onClick={() => HandleImport()} disabled={!importButtonActive} className={"bg-lacgreen mx-5 my-auto w-fit h-[2.5rem] px-2 rounded font-bold text-lg text-white transition duration-250 ease-in-out hover:bg-lacgreen-600 " + (importButtonActive ? 'hover:bg-lacgreen-600' : 'opacity-50 cursor-not-allowed')}>
                            <span className="drop-shadow">Import</span>
                        </button>
                    </div>

                </div>

                <SaveButton />

            </div>

            <div className={"w-full h-auto flex " + (loading ? "" : "hidden")}>
                <FontAwesomeIcon className="fill-current text-lacblue w-24 h-24 animate-spin m-auto" icon={solid('spinner')} />
            </div>

            <AuthorizationModal Client={selectedClientForModal} AuthId={selectedAuthId} PayorInfoTypes={payorInfoTypes} AuthorizationStatuses={authorizationStatuses} Active={isAuthorizationModalActive} setActive={setIsAuthorizationModalActive} OnModalClose={OnModalClose} ></AuthorizationModal>

            <ModalDailyScheduleEvent event={selectedEvent} Active={isEventModalActive} setActive={setIsEventModalActive} deleteEvent={deleteEvent} updateEvent={updateEvent}></ModalDailyScheduleEvent>

            <ModalNotification Title={"Success!"} Body={"Learner saved successfully."} Active={successModalActive} Icon={"Success"} setActive={setSuccessModalActive} OnModalClose={function noop() { }} />
            <ModalNotification Title={"Warning!"} Body={"Failed to save schedule. " + overlapMessage} Active={overlapModalActive} Icon={"Warning"} setActive={setOverlapModalActive} OnModalClose={function noop() { }} />

        </div>
    );
};

const locales = {
    'en-US': enUS,
}
const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
const now = new Date()
const start = endOfHour(now)
const end = addHours(start, 2)
// The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})
//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar)

export function LearnerEdit() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={ErrorComponent} loadingComponent={Loading}>
                <ProtectedPage RequiredRoles={['Administration.Learner.Write']}>
                    <LearnerEditContent />
                </ProtectedPage>
            </MsalAuthenticationTemplate>

            <UnauthenticatedTemplate>

            </UnauthenticatedTemplate>
        </>
    )
};