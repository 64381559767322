import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import BlueManLogo from '../images/BlueManBlue.png'; 
import { GlobalContext } from "../utils/GlobalContext";
import ProtectedComponent from "../utils/ProtectedComponent";


export function Home() {
    const globalContext = useContext(GlobalContext);

    const navigate = useNavigate();

    function NavigateToECL() {
        navigate("/ecl");
    }

    function NavigateToOutcomes() {
        navigate("/outcomes");
    }

    function NavigateToCredentials() {
        navigate("/credentials")
    }

    function NavigateToEDPN() {
        navigate("/edpn");
    }

    function NavigateToAuthorizations() {
        navigate("/authorizations");
    }

    return (
        <>
            <AuthenticatedTemplate>
                <div className="m-auto grid lg:grid-flow-col gap-8 md:grid-flow-rows p-4">

                    <ProtectedComponent RequiredRoles={["ECL.View", "ECL.Write", "ECL.Approve"]} >
                        <div onClick={NavigateToECL} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-lacblue hover:border-4 cursor-pointer">
                            <FontAwesomeIcon className="w-full h-[12rem] grow text-lacblue" icon={solid('file-alt')} />
                            <span className="flex text-center w-fit m-auto font-bold text-xl">eClinical Logs</span>
                        </div>
                    </ProtectedComponent>

                    <ProtectedComponent RequiredRoles={["EDPN.Read", "EDPN.Write"]} >
                        <div onClick={NavigateToEDPN} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-purple hover:border-4  cursor-pointer">
                            <FontAwesomeIcon className="w-full h-[12rem] grow text-purple" icon={solid('pencil')} />
                            <span className="flex text-center w-fit m-auto font-bold text-xl">EDPN</span>
                        </div>
                    </ProtectedComponent>

                    <ProtectedComponent RequiredRoles={["Outcomes.Read", "Outcomes.Write"]} >
                        <div onClick={NavigateToOutcomes} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-lacgreen hover:border-4  cursor-pointer">
                            <FontAwesomeIcon className="w-full h-[12rem] grow text-lacgreen" icon={solid('chart-line')} />
                            <span className="flex text-center w-fit m-auto font-bold text-xl">Outcomes</span>
                        </div>
                    </ProtectedComponent>

                    <ProtectedComponent RequiredRoles={["Credentials.Read", "Credentials.Write", "Administration.Learner.Write", "CenterLockdown"]} >
                        <div onClick={NavigateToCredentials} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-rose hover:border-4  cursor-pointer">
                            <FontAwesomeIcon className="w-full h-[12rem] grow text-rose" icon={solid('cog')} />
                            <span className="flex text-center w-fit m-auto font-bold text-xl">Administration</span>
                        </div>
                    </ProtectedComponent>

                    {/*<ProtectedComponent RequiredRoles={["Authorizations.AppAccess"]} >
                        <div onClick={NavigateToAuthorizations} className="h-[16rem] w-[16rem] bg-gray-50 border drop-shadow rounded-lg p-4 hover:border-lacyellow hover:border-4  cursor-pointer">
                            <FontAwesomeIcon className="w-full h-[12rem] grow text-lacyellow" icon={solid('clipboard-check')} />
                            <span className="flex text-center w-fit m-auto font-bold text-xl">Authorizations</span>
                        </div>
                    </ProtectedComponent>*/}

                </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div className='text-3xl text-lacblue font-bold w-full m-auto text-center'>Please sign-in.</div>
            </UnauthenticatedTemplate>
        </>
    );
 
    /*
    return (
        <>
            <AuthenticatedTemplate>
                <div className="m-auto">
                    <img className="w-[28rem] h-[28rem] mr-4" src={BlueManLogo}></img>
                </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <div className='text-3xl text-lacblue font-bold w-full m-auto text-center'>Please sign-in.</div>
            </UnauthenticatedTemplate>
        </>
    );
    */
}
