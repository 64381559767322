import React, { useContext, useEffect, useState } from 'react';

import WelcomeName from "./WelcomeName";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import BlueManLogo from '../images/BlueManWhite.png'; 
import ProtectedComponent from '../utils/ProtectedComponent';
import { GlobalContext } from '../utils/GlobalContext';
import { useIsAuthenticated } from '@azure/msal-react';
import { apiConfig, isProduction } from "../authConfig"
import { AccountMenu } from './AccountMenu';
import { SignInSignOutButton } from './SignInSignOutButton';


const NavBar = () => {

    const globalContext = useContext(GlobalContext);



    const navigate = useNavigate();

    const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
    const isAuthenticated = useIsAuthenticated();

    const [name, setName] = useState("");
    const [edpnCorrectionsCount, setEdpnCorrectionsCount] = useState<number>(0);


    const [menuOpen, setMenuOpen] = useState(false);

    const [hideDevMode, setHideDevMode] = useState(false);


    function ToggleMenu() {
        setMenuOpen(!menuOpen);
    }

    function GoToHome() {
        setMobileMenuExpanded(false);
        navigate("/");
    }

    function GoToECLHome() {
        setMobileMenuExpanded(false);
        navigate("/ecl/logs");
    }

    function GoToEDPN() {
        setMobileMenuExpanded(false);
        navigate("/edpn");
    }

    function GoToOutcomes() {
        setMobileMenuExpanded(false);
        navigate("/outcomes");
    }

    function GoToAdministration() {
        setMobileMenuExpanded(false);
        navigate("/credentials");
    }

    function GoToAuthorizations() {
        setMobileMenuExpanded(false);
        navigate("/authorizations");
    }

    function NavigateToProfilePage() {
        setMobileMenuExpanded(false);
        navigate("/profile");
    }

    useEffect(() => {
        if (globalContext.CoreAccount.name) {
            setName(globalContext.CoreAccount.name);
            UpdateCorrectionsCount();
        }
    }, [globalContext.CoreAccount.name]);

    // AUTO REFRESH EVERY MINUTE
    useEffect(() => {
        const intervalId = setInterval(() => {
            UpdateCorrectionsCount();

        }, (60 * 1000)); // 60 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    });

    function UpdateCorrectionsCount() {

        globalContext.GetToken().then((token: any) => {

            if (globalContext.CoreAccount.currentUser?.therapistID) {
                var headers = new Headers();

                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers
                };

                var url = apiConfig.apiEndpoint + "/EDPN/GetGlobalCorrectionsCount?therapistId=" + globalContext.CoreAccount.currentUser?.therapistID;

                fetch(url, options)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setEdpnCorrectionsCount(result);
                        },
                        (error) => {
                        }
                    )
            }
        })
    }

    const Loading = () => {

        if (isAuthenticated && !globalContext.CoreAccount.currentUser) {

            return (
                <FontAwesomeIcon className="text-white w-8 h-8 animate-spin mr-auto" icon={solid('spinner')} />
            );
            
        }
        else {
            return (
                <></>
            );
        }
    }


    const TestBanner = () => {

        if (isProduction) {

            return (
                <></>
            );

        }
        else
        {
            return (
                <div className="flex items-center justify-between flex-wrap p-1 noprint animate-rainbow-background bg-rainbow-gradient bg-[length:200%_200%]">
                    <span className="xl:text-2xl text-xl font-bold text-center w-full">
                        This is the test website. If you see this banner, you are working with test data.
                    </span>
                </div>
            );
        }
        /*
        else {
            return (
                <div onClick={() => setHideDevMode(false)} className="flex items-center justify-between flex-wrap bg-red p-1 noprint">
                    <span className="xl:text-2xl text-xl font-bold text-center w-full">This is the test website. If you see this banner, you are working with test data.</span>
                </div>
            );
        }
        */

    }

    const DesktopWelcomeMenu = () => {

        if (name) {
            return (
                <div className="relative inline-block text-left">

                    <div className="flex">
                        <ProtectedComponent RequiredRoles={["EDPN.Approve", "EDPN.Write"]} >
                            <span className={"m-auto pl-2 pr-2 text-2xl font-bold text-black bg-rose rounded " + (edpnCorrectionsCount == 0 ? 'hidden' : '')}>EDPN: {edpnCorrectionsCount}</span>
                        </ProtectedComponent>

                        <span className="m-3 text-2xl text-white cursor-pointer" onClick={ToggleMenu}>Hello, {name}</span>

                        <div className="relative inline-flex items-center justify-end cursor-pointer" onClick={ToggleMenu}>
                            <FontAwesomeIcon className="fill-current w-8 h-8 cursor-pointer text-lacblue-100 hover:text-white" icon={solid('bars')} />
                        </div>
                    </div>

                    <AccountMenu MenuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                </div>
            );
        }
        else {
            return (
                <div>
                    <SignInSignOutButton BigSize={false}/>
                </div>
            );
        }

    }

    const MobileWelcomeMenu = () => {

        if (name) {
            return (
                <div className="block flex">
                    <div className="flex noprint">
                        <ProtectedComponent RequiredRoles={["EDPN.Approve", "EDPN.Write"]} >
                            <span className={"m-auto pl-2 pr-2 text-2xl font-bold text-black bg-rose rounded " + (edpnCorrectionsCount == 0 ? 'hidden' : '')}>EDPN: {edpnCorrectionsCount}</span>
                        </ProtectedComponent>

                        <span className="m-3 text-2xl text-white">Hello, {name}</span>
                    </div>

                    <div className="flex hidden onlyprint">
                        <span className="m-3 text-2xl text-white">{name}</span>
                    </div>

                    <div onClick={() => setMobileMenuExpanded(!mobileMenuExpanded)} className="flex items-center rounded text-lacblue-100 hover:text-white hover:border-white noprint">
                        <FontAwesomeIcon className="fill-current w-8 h-8 cursor-pointer" icon={solid('bars')} />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>
                    <SignInSignOutButton BigSize={false} />
                </div>
            );
        }

    }

    const wrapWithSpans = (text: string) => {
        return text.split('').map((char, index) => (
            <span
                key={index}
                className="animate-rainbow-wave inline-block"
                style={{ animationDelay: `${index * 0.05}s` }}
            >
                {char === ' ' ? '\u00A0' : char}
            </span>
        ));
    };

    return (
        <div className="sticky top-0 z-50">

            <TestBanner />

            

            <nav className="flex items-center justify-between flex-wrap bg-lacblue p-3">

                <div onClick={GoToHome} className="flex xl:flex xl:items-center flex-shrink-0 text-white mr-6 cursor-pointer noprint">
                    <img className="w-8 h-8 mr-4" src={BlueManLogo}></img>
                    <span className="font-ShagExpertExotica text-3xl xl:text-2xl tracking-wide">Lighthouse Core</span>
                </div>

                <Loading />


                <div className="xl:hidden flex">
                    <MobileWelcomeMenu />
                </div>

                <div className={"block flex-grow xl:flex xl:items-center xl:!w-auto w-full " + (!mobileMenuExpanded ? 'hidden' : '')}>
                    <div className="xl:text-lg text-2xl xl:flex-grow">

                        <ProtectedComponent RequiredRoles={["ECL.View", "ECL.Write", "ECL.Approve"]} >
                            {/*<FontAwesomeIcon className="hidden xl:inline fill-current px-4 text-white" icon={solid('ellipsis-vertical')} />*/}
                            <button onClick={GoToECLHome} className={"block hover:text-lacgreen transition duration-250 ease-in-out mt-4 xl:inline-block xl:mt-0 font-bold xl:ml-4 " + (globalContext.selectedNavigationTab == "ecl" ? "text-lacgreen border-b-2 border-lacgreen" : "text-white")}>
                                eClinical Log
                            </button>
                        </ProtectedComponent>

                        <ProtectedComponent RequiredRoles={["EDPN.Read", "EDPN.Write"]} >
                            {/*<FontAwesomeIcon className="hidden xl:inline fill-current px-4 text-white" icon={solid('ellipsis-vertical')} />*/}
                            <button onClick={GoToEDPN} className={"block hover:text-lacgreen transition duration-250 ease-in-out mt-4 xl:inline-block xl:mt-0 font-bold xl:ml-4 " + (globalContext.selectedNavigationTab == "edpn" ? "text-lacgreen border-b-2 border-lacgreen" : "text-white")}>
                                eDPN
                            </button>
                        </ProtectedComponent>

                        <ProtectedComponent RequiredRoles={["Outcomes.Read", "Outcomes.Write"]} >
                            {/*<FontAwesomeIcon className="hidden xl:inline fill-current px-4 text-white" icon={solid('ellipsis-vertical')} />*/}
                            <button onClick={GoToOutcomes} className={"block hover:text-lacgreen transition duration-250 ease-in-out mt-4 xl:inline-block xl:mt-0 font-bold xl:ml-4 " + (globalContext.selectedNavigationTab == "outcomes" ? "text-lacgreen border-b-2 border-lacgreen" : "text-white")}>
                                Outcomes
                            </button>
                        </ProtectedComponent>

                        {/*
                        <ProtectedComponent RequiredRoles={["Authorizations.AppAccess"]} >
                            <button onClick={GoToAuthorizations} className={"block hover:text-lacgreen transition duration-250 ease-in-out mt-4 xl:inline-block xl:mt-0 font-bold xl:ml-4 " + (globalContext.selectedNavigationTab == "authorizations" ? "text-lacgreen border-b-2 border-lacgreen" : "text-white")}>
                                Authorizations
                            </button>
                        </ProtectedComponent>
                        */}
                        


                        <ProtectedComponent RequiredRoles={["Administration.Learner.Write"]} >
                            {/*<FontAwesomeIcon className="hidden xl:inline fill-current px-4 text-white" icon={solid('ellipsis-vertical')} />*/}
                            <button onClick={GoToAdministration} className={"block hover:text-lacgreen transition duration-250 ease-in-out mt-4 xl:inline-block xl:mt-0 font-bold xl:ml-4 " + (globalContext.selectedNavigationTab == "credentials" ? "text-lacgreen border-b-2 border-lacgreen" : "text-white")}>
                                Administration
                            </button>
                        </ProtectedComponent>

                        <div className="xl:hidden block">
                            <button onClick={NavigateToProfilePage} className="block hover:text-lacgreen  transition duration-250 ease-in-out mt-4 xl:inline-block xl:mt-0 text-white font-bold xl:p-2">
                                Edit Profile
                            </button>
                        </div>

                        <div className="xl:hidden block">
                            <SignInSignOutButton BigSize={true} />
                        </div>

                    </div>

                    <div className="hidden xl:block">
                        <DesktopWelcomeMenu />
                    </div>

                </div>

            </nav>

            
        </div>

     
    );
};

export default NavBar;